import { useNavigate } from "react-router-dom";

const DashboardCard = (props) => {
  const navigate = useNavigate();

  if (props.imageLink && props.displayName && props.helpText) {
    return (
      <div
        className="col-lg-2 col-md-4 col-sm-6 col-xs-6 text-center"
        onClick={() => props.navLink && props.newTab ? window.open(props.navLink) : navigate(props.navLink)}
        title={props.helpText}
      >
        <div className="dashboard-card">
          <img src={props.imageLink} alt=""></img>
          <span>{props.displayName}</span>
          <small>{props.helpText}</small>
        </div>
      </div >
    );
  }
};

export default DashboardCard;
