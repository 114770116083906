import { useNavigate } from "react-router-dom";
import { useCallback, useContext, Fragment, useState } from "react";
import { InputOtp } from "primereact/inputotp";
import AuthContext from "../../../auth/auth-context";
import QuestionsService from "../../../services/questions.service";
import AlertService from "../../../services/alert.service";
import UserService from "../../../services/user.service";
import "../identity.css";

const Answers = () => {
  const navigate = useNavigate();
  const [onLoadValidation, setOnLoadValidation] = useState(false);
  const [codeValue, setCodeValue] = useState();
  const [codeIsValid, setCodeIsValid] = useState(true);
  const [showEditAnswers, setShowEditAnswers] = useState(false);

  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);

  const [q1Value, setQ1Value] = useState();
  const [q2Value, setQ2Value] = useState();
  const [q3Value, setQ3Value] = useState();
  const [q4Value, setQ4Value] = useState();
  const [q5Value, setQ5Value] = useState();
  const [a1Value, setA1Value] = useState();
  const [a2Value, setA2Value] = useState();
  const [a3Value, setA3Value] = useState();
  const [a4Value, setA4Value] = useState();
  const [a5Value, setA5Value] = useState();

  const [q1IsValid, setQ1IsValid] = useState(true);
  const [q2IsValid, setQ2IsValid] = useState(true);
  const [q3IsValid, setQ3IsValid] = useState(true);
  const [q4IsValid, setQ4IsValid] = useState(true);
  const [q5IsValid, setQ5IsValid] = useState(true);
  const [a1IsValid, setA1IsValid] = useState(true);
  const [a2IsValid, setA2IsValid] = useState(true);
  const [a3IsValid, setA3IsValid] = useState(true);
  const [a4IsValid, setA4IsValid] = useState(true);
  const [a5IsValid, setA5IsValid] = useState(true);

  const [loading, setLoading] = useState(false);

  const authContext = useContext(AuthContext);

  const loadQuestions = useCallback(async () => {
    setLoading(true);
    const data = await QuestionsService.GetAllQuestions(authContext);
    if (data.isSuccess) {
      setLoading(false);
      setQuestions(data.data);
    } else {
      setLoading(false);
      AlertService.error(data.errorMessage);
    }
  }, [authContext]);

  const loadAnswers = useCallback(async () => {
    setLoading(true);
    const data = await QuestionsService.GetAllAnswers(authContext);
    if (data.isSuccess) {
      setLoading(false);
      setAnswers(data.data);
      setQ1Value(data.data[0].questionId);
      setA1Value(data.data[0].answer);
      setQ2Value(data.data[1].questionId);
      setA2Value(data.data[1].answer);
      setQ3Value(data.data[2].questionId);
      setA3Value(data.data[2].answer);
      setQ4Value(data.data[3].questionId);
      setA4Value(data.data[3].answer);
      setQ5Value(data.data[4].questionId);
      setA5Value(data.data[4].answer);
    } else {
      setLoading(false);
      AlertService.error(data.errorMessage);
    }
  }, [authContext]);

  const onCancelHandler = async (event) => {
    navigate("/profile");
  };

  const onSubmitHandler = async (event) => {
    setQ1IsValid(true);
    setA1IsValid(true);
    setQ2IsValid(true);
    setA2IsValid(true);
    setQ3IsValid(true);
    setA3IsValid(true);
    setQ4IsValid(true);
    setA4IsValid(true);
    setQ5IsValid(true);
    setA5IsValid(true);
    event.preventDefault();

    if (!q1Value) {
      setQ1IsValid(false);
      return false;
    }
    if (!a1Value) {
      setA1IsValid(false);
      return false;
    }
    if (!q2Value) {
      setQ2IsValid(false);
      return false;
    }
    if (!a2Value) {
      setA2IsValid(false);
      return false;
    }
    if (!q3Value) {
      setQ3IsValid(false);
      return false;
    }
    if (!a3Value) {
      setA3IsValid(false);
      return false;
    }
    if (!q4Value) {
      setQ4IsValid(false);
      return false;
    }
    if (!a4Value) {
      setA4IsValid(false);
      return false;
    }
    if (!q5Value) {
      setQ5IsValid(false);
      return false;
    }
    if (!a5Value) {
      setA5IsValid(false);
      return false;
    }

    if (
      q1IsValid &&
      a1IsValid &&
      q2IsValid &&
      a2IsValid &&
      q3IsValid &&
      a3IsValid &&
      q4IsValid &&
      a4IsValid &&
      q5IsValid &&
      a5IsValid
    ) {
      setShowEditAnswers(false);
    }
  };

  const onOTPSubmitHandler = async (event) => {
    event.preventDefault();

    if (!codeValue) {
      setCodeIsValid(false);
      return false;
    }

    if (codeIsValid) {
      try {
        setLoading(true);
        const result = await UserService.VerifyQrCode(codeValue, authContext);
        setLoading(false);
        if (result.isSuccess) {
          if (onLoadValidation === false) {
            setOnLoadValidation(true);
            setShowEditAnswers(true);
            setCodeValue(null);
            loadQuestions();
            loadAnswers();
          } else {
            try {
              setLoading(true);
              let payload = [
                { id: answers[0].id, questionId: q1Value, answer: a1Value },
                { id: answers[1].id, questionId: q2Value, answer: a2Value },
                { id: answers[2].id, questionId: q3Value, answer: a3Value },
                { id: answers[3].id, questionId: q4Value, answer: a4Value },
                { id: answers[4].id, questionId: q5Value, answer: a5Value },
              ];
              let data = await QuestionsService.Save(payload, authContext);
              if (data.isSuccess) {
                authContext.setAnswered(true);
                navigate("/profile");
              } else {
                AlertService.error(data.errorMessage);
              }
              setLoading(false);
            } catch (ex) {
              console.log(ex);
              setLoading(false);
            }
          }
        } else {
          setLoading(false);
          AlertService.error(result.errorMessage);
        }
      } catch (ex) {
        console.log(ex);
        setLoading(false);
      }
    }
  };

  return (
    <Fragment>
      <div
        className={
          showEditAnswers
            ? "identity-container no-background"
            : "identity-container no-background answers"
        }
      >
        {showEditAnswers && (
          <div className="identity-wrapper">
            <div className="identity-form questions-form">
              <h1>Security Questions</h1>
              <form onSubmit={onSubmitHandler}>
                <center>
                  <table>
                    <thead>
                      <tr>
                        <th>Sl. No</th>
                        <th>Question</th>
                        <th>Answer</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1.</td>
                        <td>
                          <select
                            required
                            className="form-control"
                            value={q1Value}
                            onChange={(e) => setQ1Value(e.target.value)}
                          >
                            {questions &&
                              questions.map((question) => (
                                <option key={question.id} value={question.id}>
                                  {question.question}
                                </option>
                              ))}
                          </select>
                          {!q1IsValid && (
                            <p className="error">Please select question</p>
                          )}
                        </td>
                        <td>
                          <input
                            autoFocus
                            type="text"
                            className="form-control"
                            placeholder="Answer"
                            required
                            value={a1Value}
                            maxLength="30"
                            onChange={(e) => setA1Value(e.target.value)}
                          />
                          {!a1IsValid && (
                            <p className="error">Please provide answer</p>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>2.</td>
                        <td>
                          <select
                            required
                            className="form-control"
                            value={q2Value}
                            onChange={(e) => setQ2Value(e.target.value)}
                          >
                            {questions &&
                              questions.map((question) => (
                                <option key={question.id} value={question.id}>
                                  {question.question}
                                </option>
                              ))}
                          </select>
                          {!q2IsValid && (
                            <p className="error">Please select question</p>
                          )}
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Answer"
                            required
                            value={a2Value}
                            maxLength="30"
                            onChange={(e) => setA2Value(e.target.value)}
                          />
                          {!a2IsValid && (
                            <p className="error">Please provide answer</p>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>3.</td>
                        <td>
                          <select
                            required
                            className="form-control"
                            value={q3Value}
                            onChange={(e) => setQ3Value(e.target.value)}
                          >
                            {questions &&
                              questions.map((question) => (
                                <option key={question.id} value={question.id}>
                                  {question.question}
                                </option>
                              ))}
                          </select>
                          {!q3IsValid && (
                            <p className="error">Please select question</p>
                          )}
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Answer"
                            required
                            value={a3Value}
                            maxLength="30"
                            onChange={(e) => setA3Value(e.target.value)}
                          />
                          {!a3IsValid && (
                            <p className="error">Please provide answer</p>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>4.</td>
                        <td>
                          <select
                            required
                            className="form-control"
                            value={q4Value}
                            onChange={(e) => setQ4Value(e.target.value)}
                          >
                            {questions &&
                              questions.map((question) => (
                                <option key={question.id} value={question.id}>
                                  {question.question}
                                </option>
                              ))}
                          </select>
                          {!q4IsValid && (
                            <p className="error">Please select question</p>
                          )}
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Answer"
                            required
                            value={a4Value}
                            maxLength="30"
                            onChange={(e) => setA4Value(e.target.value)}
                          />
                          {!a4IsValid && (
                            <p className="error">Please provide answer</p>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>5.</td>
                        <td>
                          <select
                            required
                            className="form-control"
                            value={q5Value}
                            onChange={(e) => setQ5Value(e.target.value)}
                          >
                            {questions &&
                              questions.map((question) => (
                                <option key={question.id} value={question.id}>
                                  {question.question}
                                </option>
                              ))}
                          </select>
                          {!q5IsValid && (
                            <p className="error">Please select question</p>
                          )}
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Answer"
                            required
                            value={a5Value}
                            maxLength="30"
                            onChange={(e) => setA5Value(e.target.value)}
                          />
                          {!a5IsValid && (
                            <p className="error">Please provide answer</p>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </center>
                <div className="mb-3 mt-2 input-icons"></div>
                <div className="text-center pt-4">
                  <button
                    type="submit"
                    className="identity-button"
                    disabled={loading}
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    className="identity-cancel-button ml-3"
                    onClick={onCancelHandler}
                    disabled={loading}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
        {showEditAnswers === false && (
          <div className="identity-form text-center p-0">
            <h1>Two Factor Verification</h1>
            <form onSubmit={onOTPSubmitHandler}>
              <div className="row m-0">
                <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                <div className="col-xl-6 col-md-12 col-sm-12 col-xs-12 mb-3 mt-2 input-icons">
                  <small>
                    Please provide the One-Time Password code generated by{" "}
                    <br />
                    <b>Microsoft Authenticator</b> in the textbox below.
                  </small>
                </div>
                <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
              </div>
              <div className="row m-0">
                <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                <div className="col-xl-6 col-md-12 col-sm-12 col-xs-12 mb-3 mt-2 m-auto">
                  <InputOtp
                    onChange={(e) => {
                      setCodeValue(e.value);
                      setLoading(false);
                    }}
                    onKeyUp={() => setLoading(false)}
                    length={6}
                    disabled={loading}
                    integerOnly
                  />
                </div>
                <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
              </div>
              <div className="text-center pt-4">
                <button
                  type="submit"
                  className="identity-button"
                  disabled={loading}
                >
                  Verify
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default Answers;
