import "../app.css";
import AlertService from "../../../services/alert.service";
import CourseService from "../../../services/course.service";
import DocumentService from "../../../services/document.service";
import AuthContext from "../../../auth/auth-context";
import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MultiSelect } from "primereact/multiselect";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";

const AddCourse = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState([]);

  const [courseId, setCourseId] = useState(0);
  const [courseName, setCourseName] = useState();
  const [description, setDescription] = useState();
  const [guide, setGuide] = useState();

  const [courseNameIsValid, setCourseNameIsValid] = useState(true);
  const [descriptionIsValid, setDescriptionIsValid] = useState(true);
  const [guideIsValid, setGuideIsValid] = useState(true);

  const courseNameRef = useRef();
  const descriptionRef = useRef();

  const authContext = useContext(AuthContext);

  const loadCourse = useCallback(async () => {
    setLoading(true);
    const data = await CourseService.Get(params.id, authContext);
    if (data.isSuccess) {
      setLoading(false);
      setCourseName(data.data.courseName);
      setDescription(data.data.description);
      setGuide(data.data.documents);
    } else {
      setLoading(false);
      AlertService.error(data.errorMessage);
    }
  }, [authContext, params.id]);

  const loadDocuments = useCallback(async () => {
    const data = await DocumentService.GetDocuments(authContext);
    if (data.isSuccess) {
      setLoading(false);
      setDocuments(data.data);
    } else {
      setLoading(false);
      AlertService.error(data.errorMessage);
    }
  }, [authContext]);

  useEffect(() => {
    loadDocuments();
  }, [loadDocuments, authContext]);

  useEffect(() => {
    if (params.id) {
      setCourseId(params.id);
      loadCourse();
    }
  }, [loadCourse, authContext, params.id]);

  const onCancelHandler = async (event) => {
    navigate("/courses");
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    let courseNameValue = courseNameRef.current.value;
    if (!courseNameValue) {
      setCourseNameIsValid(false);
    }
    let descriptionValue = descriptionRef.current.value;
    if (!descriptionValue) {
      setDescriptionIsValid(false);
    }
    let guideValue = guide;
    if (!guideValue) {
      setGuideIsValid(false);
    }

    if (courseNameIsValid && descriptionIsValid && guideIsValid) {
      setLoading(true);
      try {
        let payload = {
          courseName: courseNameValue,
          description: descriptionValue,
          documents: guideValue,
          attachments: [],
        };
        if (courseId > 0) {
          payload.id = courseId;
        }
        let data = await CourseService.Save(payload, authContext);
        if (data.isSuccess) {
          navigate("/courses");
        } else {
          AlertService.error(data.errorMessage);
        }
        setLoading(false);
      } catch (ex) {
        console.log(ex);
        setLoading(false);
      }
    }
  };

  const onConfirmDeleteHandler = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Are you sure you want to delete this course?",
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "accept",
      accept: onDeleteHandler,
    });
  };

  const onDeleteHandler = async () => {
    let data = await CourseService.Remove(courseId, authContext);
    if (data.isSuccess) {
      AlertService.success("Course details deleted successfully!");
      navigate("/courses");
    } else {
      AlertService.error(data.errorMessage);
    }
  };

  return (
    <Fragment>
      {!loading && (
        <form onSubmit={onSubmitHandler}>
          <div className="row pt-4 catalogue">
            <div className="col-md-1 col-sm-2 d-xs-none"></div>
            <div className="col-md-2 col-sm-3 col-xs-12 label">Course Name</div>
            <div className="col-md-8 col-sm-5 col-xs-12 value">
              <input
                type="text"
                className="form-control"
                placeholder="Course Name"
                maxLength="150"
                defaultValue={courseName}
                ref={courseNameRef}
                onChange={(e) => {
                  setCourseName(e.value);
                  setCourseNameIsValid(true);
                }}
              />
              {!courseNameIsValid && (
                <p className="error">Please provide course name</p>
              )}
            </div>
            <div className="col-md-1 col-sm-2 d-xs-none"></div>
          </div>
          <div className="row pt-4">
            <div className="col-md-1 col-sm-2 d-xs-none"></div>
            <div className="col-md-2 col-sm-3 col-xs-12 label">Description</div>
            <div className="col-md-8 col-sm-5 col-xs-12 value">
              <textarea
                type="text"
                className="form-control"
                placeholder="Description"
                maxLength="800"
                defaultValue={description}
                ref={descriptionRef}
                onChange={(e) => {
                  setDescription(e.value);
                  setDescriptionIsValid(true);
                }}
              />
              {!descriptionIsValid && (
                <p className="error">Please provide description</p>
              )}
            </div>
            <div className="col-md-1 col-sm-2 d-xs-none"></div>
          </div>
          <div className="row pt-4">
            <div className="col-md-1 col-sm-2 d-xs-none"></div>
            <div className="col-md-2 col-sm-3 col-xs-12 label">
              Cyber Bay Guide
            </div>
            <div className="col-md-8 col-sm-5 col-xs-12 value">
              <MultiSelect
                filter
                value={guide}
                onChange={(e) => { setGuide(e.value); setGuideIsValid(true); }}
                options={documents}
                maxSelectedLabels={5}
              />
              {!guideIsValid && (
                <p className="error">Please select cyber bay guide</p>
              )}
            </div>
            <div className="col-md-1 col-sm-2 d-xs-none"></div>
          </div>
          <div className="row pt-2 mb-3">
            <div className="col-md-12 col-sm-12">
              <div className="text-center pt-4">
                <button
                  type="submit"
                  className="submit-button"
                  disabled={loading}
                >
                  Submit
                </button>
                {courseId > 0 && (
                  <>
                    <ConfirmPopup />
                    <button
                      type="button"
                      className="remove-button ml-3"
                      onClick={onConfirmDeleteHandler}
                      disabled={loading}
                    >
                      Delete
                    </button>
                  </>
                )}
                <button
                  type="button"
                  className="cancel-button ml-3"
                  onClick={onCancelHandler}
                  disabled={loading}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    </Fragment>
  );
};

export default AddCourse;
