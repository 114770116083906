import { Fragment, useEffect } from "react";

const PracticalExamQuestion = ({
    index,
    selectedIndex,
    input,
    changeHandler
}) => {
    const assessmentId = (input && input.assessmentId) ?? 0;
    const questionId = (input && input.id) ?? 0;
    const storyBoardTitle = input && input.storyBoardTitle;
    const storyBoardInfo = input && input.storyBoardInfo;
    const taskTitle = input && input.taskTitle;
    const taskInfo = input && input.taskInfo;

    useEffect(() => {
        let payload = {
            id: questionId,
            assessmentId: assessmentId,
            storyBoardTitle: storyBoardTitle,
            storyBoardInfo: storyBoardInfo,
            taskTitle: taskTitle,
            taskInfo: taskInfo
        };
        changeHandler(index, payload);
    }, [index, assessmentId, storyBoardTitle, storyBoardInfo, taskTitle, taskInfo]);

    return (
        <Fragment>
            <div className={selectedIndex === index ? 'd-block' : 'd-none'}>
                <div className="row m-0 pl-3 pr-3">
                    <div className="col-10">
                        <span className="form-control question">{storyBoardTitle}</span>
                    </div>
                    <div className="col-2">
                    </div>
                </div>
                <div className="row m-0 pl-3 pr-3">
                    <div className="col-10">
                        <div className="story-info">{storyBoardInfo}</div>
                    </div>
                    <div className="col-2">
                    </div>
                </div>
                <div className="row m-0 pl-3 pr-3">
                    <div className="col-10">
                        <span className="form-control question">{taskTitle}</span>
                    </div>
                    <div className="col-2">
                    </div>
                </div>
                <div className="row m-0 pl-3 pr-3">
                    <div className="col-10">
                        <div className="story-info">{taskInfo}</div>
                    </div>
                    <div className="col-2">
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default PracticalExamQuestion;
