import { Fragment, useContext, useEffect, useState } from "react";
import ViewObjective from "./viewObjective";
import ViewStep from "./viewStep";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import CatalogueService from "../../../services/catalogue.service";
import AuthContext from "../../../auth/auth-context";

const View = ({ incident, clearSelection, editable, courseId, courseName, statusHandler }) => {
  const [hasObjectives, setHasObjectives] = useState(incident && incident.objectives && incident.objectives.length > 0);
  const authContext = useContext(AuthContext);

  useEffect(() => {
    setHasObjectives(incident && incident.objectives && incident.objectives.length > 0);
  }, [incident]);

  const MarkAsCompleted = async () => {
    await CatalogueService.MarkAsCompleted(incident.id, authContext);
    incident.status = 2;
    statusHandler(2);
  }

  const MarkAsIncomplete = async () => {
    await CatalogueService.MarkAsIncomplete(incident.id, authContext);
    incident.status = 0;
    statusHandler(0);
  }

  const onConfirmCompletedHandler = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Are you sure you want to mark this as completed?",
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "accept",
      accept: MarkAsCompleted,
    });
  };

  const onConfirmIncompleteHandler = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Are you sure you want to mark this as incomplete?",
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "accept",
      accept: MarkAsIncomplete,
    });
  };

  return (
    <Fragment>
      {incident && (
        <div className="row m-0">
          {hasObjectives &&
            <>
              <ConfirmPopup />
              <div className="col-9 course-title">
                {courseName}
                {incident.status !== 2 && <button type="button" className="btn-incident-back incomplete" onClick={onConfirmCompletedHandler}>
                  <i className="fa fa-check-circle"></i> <span>Incomplete</span>
                </button>}
                {incident.status === 2 && <button type="button" className="btn-incident-back completed" onClick={onConfirmIncompleteHandler}>
                  <i className="fa fa-check-circle"></i> <span>Completed</span>
                </button>}
              </div>
              <div className="col-3 course-title">
                <button type="button" className="btn-incident-back" onClick={clearSelection}>
                  <i className="fa fa-chevron-circle-left"></i> <span>Back</span>
                </button>
              </div>
            </>
          }
          {hasObjectives === false &&
            <div className="col-12 course-title">
              {courseName}
              <button type="button" className="btn-incident-back" onClick={clearSelection}>
                <i className="fa fa-chevron-circle-left"></i> <span>Back</span>
              </button>
              {incident.status !== 2 && <button type="button" className="btn-incident-back incomplete" onClick={onConfirmCompletedHandler}>
                <i className="fa fa-check-circle"></i> <span>Incomplete</span>
              </button>}
              {incident.status === 2 && <button type="button" className="btn-incident-back completed" onClick={onConfirmIncompleteHandler}>
                <i className="fa fa-check-circle"></i> <span>Completed</span>
              </button>}
            </div>
          }
          <div className={hasObjectives ? "col-md-9 col-sm-12" : "col-md-12 col-sm-12"}>
            <div className="incident-view-container">
              <div className="row m-0">
                <div className="col-12">
                  <span className="label">Incident Title: </span> {incident.incidentTitle}
                </div>
                {editable &&
                  <div className="col-12">
                    <span className="label">Tags: </span> {incident.tags}
                  </div>
                }
                {editable &&
                  <div className="col-12">
                    <span className="label">Category: </span> {incident.category}
                  </div>
                }
                <div className="col-12">
                  <span className="label">Story Board</span> <br />
                  {incident.storyBoard}
                </div>
                <div className="col-12">
                  <span className="label">Task</span> <br />
                  {incident.task}
                </div>
                <div className="col-12 d-lg-none d-md-none d-sm-block d-xs-block">
                  <span className="label">5 O's</span> <br />
                  {incident &&
                    incident.objectives &&
                    incident.objectives.length > 0 &&
                    incident.objectives.map((objective, i) => (
                      <div key={i} className="mt-3">
                        <span>{objective.objectiveName}</span> <br />
                        {objective.description}
                      </div>
                    ))}
                </div>
                <div className="col-12">
                  <span className="label">Solution</span> <br />
                  {incident &&
                    incident.steps &&
                    incident.steps.length > 0 &&
                    incident.steps.map((step, i) => (
                      <ViewStep key={i} step={step.description}></ViewStep>
                    ))}
                </div>
              </div>
            </div>
          </div>
          {hasObjectives &&
            <div className="col-3 objective-view-container">
              {incident &&
                incident.objectives &&
                incident.objectives.length > 0 &&
                incident.objectives.map((objective, i) => (
                  <ViewObjective
                    key={i}
                    objective={objective}
                  ></ViewObjective>
                ))}
            </div>
          }
        </div>
      )}
    </Fragment >
  );
};

export default View;
