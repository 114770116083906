import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { Tree } from "primereact/tree";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import AuthContext from "../../../../auth/auth-context";
import CategoryService from "../../../../services/category.service";
import AlertService from "../../../../services/alert.service";
import "../../../identity/identity.css";

const Category = () => {
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [id, setId] = useState(0);
  const [categoryName, setCategoryName] = useState();

  const authContext = useContext(AuthContext);

  const [categoryNameIsValid, setCategoryNameIsValid] = useState(true);

  const assignCategory = async (e) => {
    if (categories.filter((f) => f.id === e).length > 0) {
      let selectedCategoryData = categories.filter((f) => f.id === e)[0];
      setId(selectedCategoryData.data.value);
      setCategoryName(selectedCategoryData.data.label);
    }
  };

  const loadCategories = useCallback(async () => {
    setLoading(true);
    const data = await CategoryService.GetAll(authContext);
    if (data.isSuccess) {
      setLoading(false);
      setCategories(
        data.data.map((m) => ({
          id: m.value,
          key: m.value,
          label: m.label,
          data: m,
        }))
      );
    } else {
      setLoading(false);
      AlertService.error(data.errorMessage);
    }
  }, [authContext]);

  useEffect(() => {
    loadCategories();
  }, [loadCategories, authContext]);

  const clearData = () => {
    setSelectedCategory(0);
    setId(0);
    setCategoryName(null);
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    let isValid = true;
    if (!categoryName) {
      setCategoryNameIsValid(false);
      isValid = false;
    }
    if (isValid) {
      setLoading(true);
      try {
        let payload = {
          label: categoryName
        };
        if (id > 0) {
          payload.value = id;
        }
        let data = await CategoryService.Save(payload, authContext);
        if (data.isSuccess) {
          clearData();
          await loadCategories();
          AlertService.success("Category details saved successfully!");
        } else {
          AlertService.error(data.errorMessage);
        }
        setLoading(false);
      } catch (ex) {
        console.log(ex);
        setLoading(false);
      }
    }
  };

  const onCancelHandler = async (e) => {
    e.preventDefault();
    clearData();
  };


  const onConfirmDeleteHandler = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Are you sure you want to delete the selected category?",
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "accept",
      accept: onDeleteHandler,
    });
  };

  const onDeleteHandler = async () => {
    let data = await CategoryService.Remove(id, authContext);
    if (data.isSuccess) {
      clearData();
      await loadCategories();
      AlertService.success("Category details deleted successfully!");
    } else {
      AlertService.error(data.errorMessage);
    }
  };


  return (
    <Fragment>
      <div className="container-fluid">
        <h2>Categories</h2>
        <div className="row m-0">
          <div className="col-lg-4 d-none d-lg-block">
            <Tree
              value={categories}
              selectionMode="single"
              selectionKeys={selectedCategory}
              onSelectionChange={(e) => {
                setSelectedCategory(e.value);
                assignCategory(e.value);
              }}
              className="w-full"
              loading={loading}
            />
          </div>
          <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
            <div className="row pt-4 d-block d-lg-none">
              <div className="col-12">
                <select
                  className="form-control"
                  value={selectedCategory}
                  onChange={(e) => {
                    setSelectedCategory(Number(e.target.value));
                    assignCategory(Number(e.target.value));
                  }}
                >
                  {categories &&
                    categories.map((agent) => (
                      <option key={agent.id} value={agent.id}>
                        {agent.label}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <form onSubmit={onSubmitHandler}>
              <div className="row pt-4">
                <div className="col-md-1 col-sm-2 d-xs-none"></div>
                <div className="col-md-2 col-sm-3 col-xs-12 label">
                  Category Name
                </div>
                <div className="col-md-8 col-sm-5 col-xs-12 value">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Category Name"
                    maxLength="150"
                    defaultValue={categoryName}
                    disabled={loading}
                    onChange={(e) => {
                      setCategoryName(e.target.value);
                      setCategoryNameIsValid(true);
                    }}
                  />
                  {!categoryNameIsValid && (
                    <p className="error">Please provide category name.</p>
                  )}
                </div>
                <div className="col-md-1 col-sm-2 d-xs-none"></div>
              </div>
              <div className="row pt-2 mb-3">
                <div className="col-md-12 col-sm-12">
                  <div className="text-center pt-4">
                    <button
                      type="submit"
                      className="submit-button"
                      disabled={loading}
                    >
                      Submit
                    </button>
                    <ConfirmPopup />
                    {id > 0 && (
                      <button
                        type="button"
                        className="remove-button ml-3"
                        onClick={onConfirmDeleteHandler}
                        disabled={loading}
                      >
                        Delete
                      </button>
                    )}
                    <button
                      type="button"
                      className="cancel-button ml-3"
                      onClick={onCancelHandler}
                      disabled={loading}
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Category;
