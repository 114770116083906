import { Fragment } from "react";

const Card = ({ id, no, title, active, clickHandler, status }) => {
  return (
    <Fragment>
      <div
        className={`card ${active ? 'active' : ''} ${status === 2 ? 'completed' : ''}`}
        onClick={() => clickHandler(id)}
      >
        <div>
          <div className="row m-0">
            <div className="col-6 p-0">
              <div className="name">Incident {no}</div>
            </div>
            <div className="col-6 p-0">
              <span className={`dot ${status === 1 ? `in-progress` : ``}  ${status === 2 ? `completed` : ``}`}></span>
            </div>
          </div>
          <div className="title" title={title}>
            {title}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Card;
