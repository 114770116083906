import './courses.css';
import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AuthContext from "../../../auth/auth-context";
import CourseService from "../../../services/course.service";
import AlertService from "../../../services/alert.service";

const CourseIncidents = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [csearchText, setCSearchText] = useState('');
    const [incidents, setIncidents] = useState([]);
    const [courseIncidents, setCourseIncidents] = useState([]);
    const [srchCourseIncidents, setSrchCourseIncidents] = useState([]);
    const [srchIncidents, setSrchIncidents] = useState([]);
    const authContext = useContext(AuthContext);

    const courseAccess = authContext.getPermissions("courses");
    if (courseAccess.filter((f) => f.access.includes(1)).length === 0) {
        navigate("/");
    }
    const showAdd = courseAccess.filter((f) => f.access.includes(2)).length > 0;
    const showEdit = courseAccess.filter((f) => f.access.includes(3)).length > 0;

    const loadCourseIncidents = useCallback(async () => {
        setLoading(true);
        const data = await CourseService.GetIncidents(params.id, '', authContext);
        if (data.isSuccess) {
            data.data && data.data.mappedIncidents && data.data.mappedIncidents.forEach(element => {
                element.selected = false;
            });
            data.data && data.data.allIncidents && data.data.allIncidents.forEach(element => {
                element.selected = false;
            });
            setCourseIncidents(data.data.mappedIncidents);
            if (data.data && data.data.mappedIncidents && data.data.mappedIncidents.length > 0) {
                setIncidents([...data.data.allIncidents.filter(f => data.data.mappedIncidents.filter(d => d.value === f.value).length === 0)]);
            } else {
                setIncidents([...data.data.allIncidents]);
            }
            setLoading(false);
        } else {
            setLoading(false);
            AlertService.error(data.errorMessage);
        }
    }, [params, authContext]);

    useEffect(() => {
        if (params.id) {
            loadCourseIncidents();
        }
    }, [params, loadCourseIncidents, authContext]);

    useEffect(() => {
        setSrchIncidents(incidents.filter(f => f.label.toLowerCase().indexOf(searchText) !== -1));
    }, [searchText, incidents]);

    useEffect(() => {
        setSrchCourseIncidents(courseIncidents.filter(f => f.label.toLowerCase().indexOf(csearchText) !== -1));
    }, [csearchText, courseIncidents]);

    const incidentSelected = (m) => {
        setIncidents(prevIncidents => {
            if (prevIncidents && prevIncidents.length > 0) {
                let incident = prevIncidents.filter(f => f.value === m.value);
                if (incident && incident.length > 0) {
                    incident[0].selected = !m.selected;
                }
                return [...prevIncidents];
            } else {
                return [];
            }
        });
    }

    const courseIncidentSelected = (m) => {
        setCourseIncidents(prevIncidents => {
            if (prevIncidents && prevIncidents.length > 0) {
                let incident = prevIncidents.filter(f => f.value === m.value);
                if (incident && incident.length > 0) {
                    incident[0].selected = !m.selected;
                }
                return [...prevIncidents];
            } else {
                return [];
            }
        });
    }

    const moveAllRight = (e) => {
        e.preventDefault();
        if (incidents && incidents.length > 0) {
            setCourseIncidents(prevIncidents => {
                incidents.forEach(f => f.selected = false);
                if (prevIncidents && prevIncidents.length > 0) {
                    return [...prevIncidents, ...incidents];
                } else {
                    return [...incidents];
                }
            });
            setIncidents([]);
        }
    }

    const moveRight = (e) => {
        e.preventDefault();
        if (incidents && incidents.length > 0) {
            let selectedIncidents = incidents.filter(f => f.selected === true);
            if (selectedIncidents.length === 0) {
                AlertService.error('Please select incident to move.');
            } else {
                selectedIncidents.forEach(f => f.selected = false);
                setCourseIncidents(prevIncidents => {
                    if (prevIncidents && prevIncidents.length > 0) {
                        return [...prevIncidents, ...selectedIncidents];
                    } else {
                        return [...selectedIncidents];
                    }
                });
                setIncidents(prevIncidents => {
                    if (prevIncidents && prevIncidents.length > 0) {
                        prevIncidents = prevIncidents.filter(f => selectedIncidents.filter(s => s.value === f.value).length === 0);
                        return [...prevIncidents];
                    } else {
                        return [];
                    }
                });
            }
        }
    }

    const moveAllLeft = (e) => {
        e.preventDefault();
        if (courseIncidents && courseIncidents.length > 0) {
            courseIncidents.forEach(f => f.selected = false);
            setIncidents(prevIncidents => {
                if (prevIncidents && prevIncidents.length > 0) {
                    return [...prevIncidents, ...courseIncidents];
                } else {
                    return [...courseIncidents];
                }
            });
            setCourseIncidents([]);
        }
    }

    const moveLeft = (e) => {
        e.preventDefault();
        if (courseIncidents && courseIncidents.length > 0) {
            let selectedIncidents = courseIncidents.filter(f => f.selected === true);
            if (selectedIncidents.length === 0) {
                AlertService.error('Please select incident to move.');
            } else {
                selectedIncidents.forEach(f => f.selected = false);
                setIncidents(prevIncidents => {
                    if (prevIncidents && prevIncidents.length > 0) {
                        return [...prevIncidents, ...selectedIncidents];
                    } else {
                        return [...selectedIncidents];
                    }
                });
                setCourseIncidents(prevIncidents => {
                    if (prevIncidents && prevIncidents.length > 0) {
                        prevIncidents = prevIncidents.filter(f => selectedIncidents.filter(s => s.value === f.value).length === 0);
                        return [...prevIncidents];
                    } else {
                        return [];
                    }
                });
            }
        }
    }

    const onSubmitHandler = async (e) => {
        e.preventDefault();
        if (!courseIncidents || courseIncidents.length === 0) {
            AlertService.error('Please select atleast an incident.');
        } else {
            setLoading(true);
            const payload = {
                courseId: params.id,
                incidents: courseIncidents.map(m => Number(m.value)),
                mappedIncidents: []
            }
            let data = await CourseService.SaveIncidents(payload, authContext);
            if (data.isSuccess) {
                AlertService.success('Selected Incidents are added to the course.');
            } else {
                AlertService.error(data.errorMessage);
            }
            setLoading(false);
        }
    }

    return (
        <Fragment>
            <div className="row m-0">
                <div className="col-12 course-title">
                    <button type="button" className="btn-incident-back" onClick={() => navigate('/courses')}>
                        <i className="fa fa-chevron-circle-left"></i> <span>Back</span>
                    </button>
                </div>
            </div>
            <div className="row m-0 course-incidents-container">
                <div className="col-5">
                    <div className='repo-incidents'>
                        <div className='title'>Incidents Master Repository</div>
                        <div className="form-group has-search search-group mb-0">
                            <span className="fa fa-search form-control-feedback"></span>
                            <input type="text" value={searchText} onChange={(e) => setSearchText(e.target.value)} className="form-control" placeholder="Search" />
                            <span className="input-group-append">
                                <button className="btn border-0" type="button" onClick={() => setSearchText('')}>
                                    <i className="fa fa-times"></i>
                                </button>
                            </span>
                        </div>
                        <div className='repo-incidents-container'>
                            {srchIncidents && srchIncidents.length > 0 &&
                                srchIncidents.map((m, i) => (
                                    <div key={i} className={m.selected ? 'incident-item selected' : 'incident-item'} onClick={() => incidentSelected(m)}>{m.label}</div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className="col-2">
                    <div className='action-container'>
                        <button disabled={!showAdd && !showEdit} onClick={moveAllLeft}>&lt;&lt;</button>
                        <button disabled={!showAdd && !showEdit} onClick={moveLeft}>&lt;</button>
                        <button disabled={!showAdd && !showEdit} onClick={moveRight}>&gt;</button>
                        <button disabled={!showAdd && !showEdit} onClick={moveAllRight}>&gt;&gt;</button>
                    </div>
                </div>
                <div className="col-5">
                    <div className='course-incidents'>
                        <div className='title'>Selected Incidents</div>
                        <div className="form-group has-search search-group">
                            <span className="fa fa-search form-control-feedback"></span>
                            <input type="text" value={csearchText} onChange={(e) => setCSearchText(e.target.value)} className="form-control" placeholder="Search" />
                            <span className="input-group-append">
                                <button className="btn border-0" type="button" onClick={() => setCSearchText('')}>
                                    <i className="fa fa-times"></i>
                                </button>
                            </span>
                        </div>
                        <div className='course-incidents-container'>
                            {srchCourseIncidents && srchCourseIncidents.length > 0 &&
                                srchCourseIncidents.map((m, i) => (
                                    <div key={i} className={m.selected ? 'incident-item selected' : 'incident-item'} onClick={() => courseIncidentSelected(m)}>{m.label}</div>
                                ))}
                        </div>
                    </div>
                </div>
                <button
                    type="button"
                    className="submit-button float-right"
                    onClick={onSubmitHandler}
                    disabled={loading}
                >
                    Submit
                </button>
            </div>
        </Fragment >
    );
};

export default CourseIncidents;
