import { createSlice } from "@reduxjs/toolkit";

const apiSlice = createSlice({
    name: "user",
    initialState: {
    },
    reducers: {
    },
});

export const apiActions = apiSlice.actions;
export default apiSlice;
