import { Fragment } from "react";
import Attachment from "./attachment";

const View = ({ document, clearSelection }) => {
  return (
    <Fragment>
      {document && (
        <div className="doc-container">
          <div className="row m-0">
            <div className="col-12 course-title">
              <button type="button" className="btn-incident-back" onClick={clearSelection}>
                <i className="fa fa-chevron-circle-left"></i> <span>Back</span>
              </button>
            </div>
          </div>
          <div className="row m-0">
            {/* <div className="col-12">
              <span>Document Name: </span> {document.documentName}
            </div>
            <div className="col-12">
              <span>Description</span> <br />
              {document.description}
            </div> */}
            <div className="col-12">
              {/* <span>Attachments</span> <br /> */}
              <div className="attachment-container">
                {document.attachments &&
                  document.attachments.length > 0 &&
                  document.attachments.map((m, i) => (
                    <Attachment key={i} no={i} attachment={m}></Attachment>
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default View;
