import request from "./request";

const ReviewMCQExamService = {
  Get: async (id, context) => {
    return await request.sendRequest(`/reviewmcqexam/${id}`, context.token);
  },
  GetByExam: async (id, context) => {
    return await request.sendRequest(`/reviewmcqexam/exam/${id}`, context.token);
  },
};

export default ReviewMCQExamService;
