import { Fragment, useContext, useState } from "react";
import AuthContext from "../../../../auth/auth-context";
import AlertService from "../../../../services/alert.service";
import "../../../identity/identity.css";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from 'primereact/button';
import { CSVLink } from "react-csv";
import QuizMCQService from "../../../../services/quizmcq.service";

const UploadQuizMCQ = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState();
  const [buttonText, setButtonText] = useState('Upload');
  const [results, setResults] = useState([]);

  const headers = [
    { label: "Question", key: "question" },
    { label: "Option1", key: "option1" },
    { label: "Option1Answer", key: "option1Answer" },
    { label: "Option2", key: "option2" },
    { label: "Option2Answer", key: "option2Answer" },
    { label: "Option3", key: "option3" },
    { label: "Option3Answer", key: "option3Answer" },
    { label: "Option4", key: "option4" },
    { label: "Option4Answer", key: "option4Answer" },
    { label: "Option5", key: "option5" },
    { label: "Option5Answer", key: "option5Answer" },
    { label: "HasGraceMark", key: "hasGraceMark" },
    { label: "Status", key: "status" },
    { label: "Reason", key: "reason" }];

  const authContext = useContext(AuthContext);

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    setLoading(true);
    setButtonText('Processing');
    const formData = new FormData();

    formData.append('file', file);
    formData.append('filename', file.name);
    try {
      let data = await QuizMCQService.ImportMCQ(params.id, formData, authContext);
      if (data.isSuccess) {
        setResults(data.data);
        AlertService.success('Quiz MCQ Questions are imported successfully!');
      } else {
        AlertService.error(data.errorMessage);
      }
    } catch { }
    setButtonText('Upload');
    setLoading(false);
  };

  const handleChange = (event) => {
    setFile(event.target.files[0]);
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <h2>Upload Quiz  MCQ Questions</h2>
        <div className="row m-0 pt-3">
          <div className="col-3 text-right">Select Excel File</div>
          <div className="col-9">
            <input type="file" className="form-control" onChange={handleChange}></input>
          </div>
        </div>
        <div className="row pt-2 mb-3">
          <div className="col-md-12 col-sm-12">
            <div className="text-center pt-4">
              <Button label={buttonText}
                loading={loading}
                onClick={onSubmitHandler} />
              {loading === false && results.length > 0 && <CSVLink data={results} filename={"Quiz-MCQ-Upload-Results.csv"} className="btn btn-success p-button mr-2 ml-2" headers={headers}>Download Results</CSVLink>}
              <button
                type="button"
                className="cancel-button ml-3"
                onClick={() => navigate(`/master/quizzes/mcq/${params.id}`)}
                disabled={loading}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment >
  );
};

export default UploadQuizMCQ;
