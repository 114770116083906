import { useNavigate, useParams } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import UserService from "../../../services/user.service";
import Header from "../header";
import "../identity.css";

const RejectUser = () => {
    const params = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [processed, setProcessed] = useState(false);
    const [reason, setReason] = useState();
    const [reasonIsValid, setReasonIsValid] = useState(true);
    const [statusText, setStatusText] = useState('User rejected successfully.');

    useEffect(() => {
    }, [params]);

    const onSubmitHandler = async () => {
        setReasonIsValid(true);
        let valid = true;
        if (!reason) {
            setReasonIsValid(false);
            valid = false;
        }
        if (valid) {
            setProcessed(true);
            setLoading(true);
            let results = await UserService.RejectUser({ code: params.code, reason });
            if (results.isSuccess === false) {
                setStatusText(results.errorMessage);
            }
            setLoading(false);
        }
    };

    const onCancelHandler = () => {
        navigate('/');
    }

    return (
        <Fragment>
            <div className="identity-container no-background">
                <Header />
                {processed &&
                    <div className="row identity-wrapper">
                        {loading === false && <div className="col-12 text-center label">{statusText}</div>}
                        {loading && <div className="col-12 text-center mt-auto label"><i className="fa fa-spinner fa-spin fa-fw"></i> Processing...</div>}                    </div>
                }
                {processed === false &&
                    <div className="identity-wrapper">
                        <div className="identity-form">
                            <form onSubmit={onSubmitHandler}>
                                <div className="row m-0">
                                    <div className="col-3"></div>
                                    <div className="col-6">
                                        <span className="label">Reason for Rejection</span><br />
                                        <textarea
                                            type="text"
                                            className="form-control"
                                            placeholder="Type here.."
                                            maxLength="500"
                                            style={{ 'minHeight': '250px' }}
                                            onChange={(e) => {
                                                setReason(e.target.value);
                                                setReasonIsValid(false);
                                                if (e.target.value) {
                                                    setReasonIsValid(true);
                                                }
                                            }}
                                        />
                                        {!reasonIsValid && (
                                            <p className="error">Please provide reason</p>
                                        )}
                                    </div>
                                    <div className="col-3"></div>
                                </div>
                                <div className="row pt-2 mb-3">
                                    <div className="col-3"></div>
                                    <div className="col-6">
                                        <div className="text-center pt-4">
                                            <button
                                                type="submit"
                                                className="submit-button"
                                                disabled={loading}
                                            >
                                                Submit
                                            </button>
                                            <button
                                                type="button"
                                                className="cancel-button ml-3"
                                                onClick={onCancelHandler}
                                                disabled={loading}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-3"></div>
                                </div>
                            </form>
                        </div>
                    </div>
                }
            </div>
        </Fragment>
    );
};

export default RejectUser;
