import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Carousel } from "primereact/carousel";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import AuthContext from "../../../../auth/auth-context";
import DocumentTypeService from "../../../../services/documenttype.service";
import AlertService from "../../../../services/alert.service";
import Card from "./card";
import View from "./view";

const DocumentTypes = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [selectedCardId, setSelectedCardId] = useState(0);
    const [documentTypes, setDocumentTypes] = useState([]);
    const [documentType, setDocumentType] = useState({});
    const authContext = useContext(AuthContext);

    const responsiveOptions = [
        {
            breakpoint: "767px",
            numVisible: 3,
            numScroll: 1,
        },
        {
            breakpoint: "575px",
            numVisible: 2,
            numScroll: 1,
        },
    ];

    const documentAccess = authContext.getPermissions("documenttypes");
    if (documentAccess.filter((f) => f.access.includes(1)).length === 0) {
        navigate("/");
    }
    const showAdd = documentAccess.filter((f) => f.access.includes(2)).length > 0;
    const showEdit = documentAccess.filter((f) => f.access.includes(3)).length > 0;
    const showDelete = documentAccess.filter((f) => f.access.includes(4)).length > 0;

    const loadDocumentTypes = useCallback(async () => {
        setLoading(true);
        const data = await DocumentTypeService.GetAll(authContext);
        if (data.isSuccess) {
            setLoading(false);
            let i = 0;
            data.data.forEach((element) => {
                i++;
                element.no = i;
            });
            setDocumentTypes(data.data);
        } else {
            setLoading(false);
            AlertService.error(data.errorMessage);
        }
    }, [authContext]);

    useEffect(() => {
        loadDocumentTypes();
    }, [loadDocumentTypes, authContext]);

    const onCardClick = async (id) => {
        setSelectedCardId(id);
        loadDocumentType(id);
    };

    const loadDocumentType = useCallback(
        async (cardId) => {
            setLoading(true);
            const data = await DocumentTypeService.Get(cardId, authContext);
            if (data.isSuccess) {
                setLoading(false);
                setDocumentType(data.data);
            } else {
                setLoading(false);
                AlertService.error(data.errorMessage);
            }
        },
        [authContext]
    );

    const cardTemplate = (document) => {
        return (
            <Card
                id={document.id}
                title={document.documentType}
                active={document.id === selectedCardId}
                clickHandler={() => onCardClick(document.id)}
            ></Card>
        );
    };

    const onEditHandler = async () => {
        navigate("/documenttypes/edit/" + documentType.id);
    };

    const onAddHandler = async () => {
        navigate("/documenttypes/add");
    };

    const onConfirmDeleteHandler = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: "Are you sure you want to delete the selected document type?",
            icon: "pi pi-exclamation-triangle",
            defaultFocus: "accept",
            accept: onDeleteHandler,
        });
    };

    const onDeleteHandler = async () => {
        let data = await DocumentTypeService.Remove(selectedCardId, authContext);
        if (data.isSuccess) {
            setDocumentType(undefined);
            await loadDocumentTypes();
            AlertService.success("Document type details deleted successfully!");
        } else {
            AlertService.error(data.errorMessage);
        }
    };

    return (
        <Fragment>
            {selectedCardId === 0 && (
                <div className="container-fluid">
                    <div className="row">
                        {!loading &&
                            documentTypes &&
                            documentTypes.map((document, i) => (
                                <div key={i} className="col-lg-2 col-md-4 col-sm-6 col-12">
                                    <Card
                                        id={document.id}
                                        title={document.documentType}
                                        active={document.id === selectedCardId}
                                        clickHandler={onCardClick}
                                    ></Card>
                                </div>
                            ))}
                        {!loading && documentTypes && documentTypes.length === 0 && (
                            <div className="col-12">No documents available...</div>
                        )}
                        {loading && <div className="col-12">Loading...</div>}
                    </div>
                </div>
            )}
            {selectedCardId > 0 && !loading && (
                <>
                    <div className="row d-none d-md-flex">
                        <div className="col-md-2 col-sm-4">
                            <div className="incident-list">
                                <Carousel
                                    value={documentTypes}
                                    numVisible={4}
                                    numScroll={1}
                                    circular={true}
                                    orientation="vertical"
                                    showIndicators={false}
                                    verticalViewPortHeight="calc(100vh - 170px)"
                                    itemTemplate={cardTemplate}
                                />
                            </div>
                        </div>
                        <div className="col-md-10 col-sm-8 view">
                            {!loading && <View document={documentType}></View>}
                        </div>
                    </div>
                    <div className="row d-md-none d-sm-flex d-flex h-carousel-container">
                        <Carousel
                            value={documentTypes}
                            numVisible={2}
                            numScroll={1}
                            showIndicators={false}
                            responsiveOptions={responsiveOptions}
                            itemTemplate={cardTemplate}
                            circular
                            className="h-carousel"
                        />
                    </div>
                    <div className="row d-md-none d-sm-flex d-flex h-view">
                        {!loading && documentType.id === selectedCardId && (
                            <View document={documentType}></View>
                        )}
                    </div>
                </>
            )}
            {showAdd && !loading && (
                <button
                    type="button"
                    onClick={onAddHandler}
                    className="floating-button"
                >
                    <i className="fa fa-plus"></i>
                </button>
            )}
            {selectedCardId > 0 && showEdit && !loading && (
                <button
                    type="button"
                    onClick={onEditHandler}
                    className="floating-button mr-6"
                >
                    <i className="fa fa-pencil"></i>
                </button>
            )}
            {selectedCardId > 0 && showDelete && (
                <>
                    <ConfirmPopup />
                    <button
                        type="button"
                        onClick={onConfirmDeleteHandler}
                        className="floating-button remove"
                        style={{ "marginRight": '6rem' }}
                    >
                        <i className="fa fa-trash"></i>
                    </button>
                </>
            )}
        </Fragment>
    );
};

export default DocumentTypes;
