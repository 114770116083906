import { Fragment } from "react";

const ViewObjective = ({ objective }) => {
  return (
    <Fragment>
      <div className="row m-0 objective">
        <div className="col-12">
          <span className="label">{objective.objectiveName}</span>
        </div>
        <div className="col-12">{objective.description}</div>
      </div>
    </Fragment>
  );
};

export default ViewObjective;
