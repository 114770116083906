import { Fragment } from "react";

const Card = ({ id, title, active, clickHandler }) => {
  console.log(title);
  return (
    <Fragment>
      <div
        className={active ? "card type active" : "card type"}
        onClick={() => clickHandler(id)}
      >
        <div>
          <div className="name">{title}</div>
        </div>
      </div>
    </Fragment>
  );
};

export default Card;
