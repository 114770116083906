import UserService from "../../../services/user.service";
import AlertService from "../../../services/alert.service";
import AuthContext from "../../../auth/auth-context";
import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import "./profile.css";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const navigate = useNavigate();
  const [displayName, setDisplayName] = useState();
  const [email, setEmail] = useState();
  const [mobileNo, setMobileNo] = useState();
  const [mfaSetUp, setMFASetUp] = useState();
  const [sqAnswers, setSQAnswers] = useState();
  const [loading, setLoading] = useState(false);

  const authContext = useContext(AuthContext);

  const loadUserInfo = useCallback(async () => {
    setLoading(true);
    const data = await UserService.GetProfile(authContext);
    if (data.isSuccess) {
      setLoading(false);
      setDisplayName(data.data.displayName);
      setEmail(data.data.email);
      setMobileNo(data.data.mobileNo);
      setMFASetUp(data.data.mfaSetupCompleted ? "Yes" : "No");
      setSQAnswers(data.data.isAnswered ? "Answered" : "Pending");
    } else {
      setLoading(false);
      AlertService.error(data.errorMessage);
    }
  }, [authContext]);

  useEffect(() => {
    loadUserInfo();
  }, [loadUserInfo, authContext]);

  const sendMFAQR = async () => {
    setLoading(true);
    const result = await UserService.SendMFAOTP(authContext);
    if (result.isSuccess) {
      setLoading(false);
      navigate("/configmfaotp");
    } else {
      setLoading(false);
      AlertService.error(result.errorMessage);
    }
    setLoading(false);
  };

  return (
    <Fragment>
      {!loading && (
        <div className="profile profile-container">
          <div className="row m-0">
            <div className="col-md-4 d-md-block d-none">
              <h1>Profile</h1>
            </div>
            <div className="col-md-8 col-sm-12 col-xs-12">
              <h1 className="d-block d-md-none">Profile</h1>
              <div className="row pt-5">
                <div className="col-md-2 col-sm-3 d-none d-sm-block profile-icon">
                  <div>
                    <img
                      className="display-name"
                      alt="Display Name"
                      src="assets/img/name.png"
                    ></img>
                  </div>
                </div>
                <div className="col-md-2 col-sm-3 col-xs-6 profile-name">
                  Display
                </div>
                <div className="col-md-8 col-sm-6 profile-value">
                  {displayName}
                </div>
              </div>
              <div className="row pt-5">
                <div className="col-md-2 col-sm-3 d-none d-sm-block profile-icon">
                  <div>
                    <img
                      className="email"
                      alt="Email"
                      src="assets/img/email.png"
                    ></img>
                  </div>
                </div>
                <div className="col-md-2 col-sm-3 col-xs-6 profile-name">
                  Email
                </div>
                <div className="col-md-8 col-sm-6 profile-value">{email}</div>
              </div>
              <div className="row pt-5">
                <div className="col-md-2 col-sm-3 d-none d-sm-block profile-icon">
                  <div>
                    <img
                      className="mobile"
                      alt="Mobile No"
                      src="assets/img/mobile.png"
                    ></img>
                  </div>
                </div>
                <div className="col-md-2 col-sm-3 col-xs-6 profile-name">
                  Mobile No
                </div>
                <div className="col-md-8 col-sm-6 profile-value">
                  {mobileNo}
                </div>
              </div>
              <div className="row pt-5">
                <div className="col-md-2 col-sm-3 d-none d-sm-block profile-icon">
                  <div>
                    <img
                      className="mfa"
                      alt="MFA"
                      src="assets/img/mfa.png"
                    ></img>
                  </div>
                </div>
                <div className="col-md-2 col-sm-3 col-xs-6 profile-name">
                  MFA Setup Completed
                </div>
                <div className="col-md-8 col-sm-6 profile-value">
                  <span className="font-weight-bold">{mfaSetUp}</span> =&gt;
                  <button
                    className="pl-2 anchor"
                    title="Click here to Re-Configure MFA configuration"
                    onClick={() => sendMFAQR()}
                  >
                    Re-Configure
                  </button>
                  <br />
                  <small>
                    If you re-configure, your security keys will be reset and
                    you cannot use existing Microsoft Authenticator. You must
                    remove existing account in Microsoft Authenticator and add
                    new.
                  </small>
                </div>
              </div>
              <div className="row pt-5">
                <div className="col-md-2 col-sm-3 d-none d-sm-block profile-icon">
                  <div>
                    <img
                      className="security-questions"
                      alt="Security Questions"
                      src="assets/img/questions.png"
                    ></img>
                  </div>
                </div>
                <div className="col-md-2 col-sm-3 col-xs-6 profile-name">
                  Security Questions
                </div>
                <div className="col-md-8 col-sm-6 profile-value">
                  <span className="font-weight-bold">{sqAnswers}</span> =&gt;
                  <a
                    className="pl-2"
                    title="Click here to Change/Update your Security Questions/Answers"
                    href="#/securityanswers"
                  >
                    Change/Update Security Questions
                  </a>
                </div>
              </div>
              <div className="row pt-2">
                <div className="col-md-4 col-sm-6 d-none d-sm-block text-right"></div>
                <div className="col-md-8 col-sm-6">
                  <a
                    className="pl-2"
                    title="Click here to edit your profile"
                    href="#/profile/edit"
                  >
                    Edit Profile
                  </a>{" "}
                  /
                  <a
                    className="pl-2"
                    title="Click here to change your current password"
                    href="#/change"
                  >
                    Change Password
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Profile;
