import { Fragment, useEffect, useRef, useState } from "react";
// import { Editor } from "primereact/editor";
import { Editor } from "@tinymce/tinymce-react";

const SolutionStep = ({
  id,
  stepName,
  stepText,
  isValid,
  removeHandler,
  valueHandler,
}) => {
  const editorRef = useRef(stepText);
  const [stepValue, setStepValue] = useState();
  const [stepValueIsValid, setStepValueIsValid] = useState(isValid);

  useEffect(() => {
    setStepValue(stepText ?? "<p></p>");
  }, [stepText]);

  return (
    <Fragment>
      <div className="row solution-step">
        <div className="col-6">
          <small>{stepName}</small>
        </div>
        <div className="col-6">
          <button
            type="button"
            title="Remove Step"
            className="float-right text-danger btn-icon"
            onClick={() => removeHandler(id)}
          >
            <i className="pi pi-trash"></i>
          </button>
        </div>
        <div className="col-md-12 col-sm-12 col-xs-12">
          <Editor
            tinymceScriptSrc={
              process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"
            }
            onInit={(evt, editor) => (editorRef.current = editor)}
            value={stepValue}
            licenseKey={'gpl'}
            onEditorChange={(e) => {
              setStepValueIsValid(true);
              if (!e) {
                setStepValueIsValid(false);
              }
              setStepValue(e);
              valueHandler(id, e);
            }}
            init={{
              height: 500,
              menubar: false,
              plugins: [
                "advlist",
                "autolink",
                "lists",
                "link",
                "image",
                "charmap",
                "anchor",
                "searchreplace",
                "visualblocks",
                "code",
                "fullscreen",
                "insertdatetime",
                "media",
                "table",
                "preview",
                "help",
                "wordcount",
              ],
              toolbar:
                "fontfamily fontsize bold italic underline strikethrough backcolor forecolor h1 h2 hr alignleft aligncenter " +
                "alignright alignjustify bullist numlist outdent indent link image blockquote subscript superscript removeformat help",
              content_style:
                "body { font-family: 'Trebuchet MS'; backgroud-color: #f9f9f9 }",
            }}
          />
          {!stepValueIsValid && (
            <p className="error">Please provide {stepName}</p>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default SolutionStep;
