import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import DocumentService from "../../../services/document.service";
import AuthContext from "../../../auth/auth-context";
import Viewer from "./viewer";
// import { Sidebar } from "primereact/sidebar";
import AlertService from "../../../services/alert.service";

const Attachment = ({ no, attachment }) => {
  no++;
  const authContext = useContext(AuthContext);
  const [pdfData, setPDFData] = useState();
  const [loading, setLoading] = useState(false);

  const downloadFile = useCallback(async () => {
    setLoading(true);
    const data = await DocumentService.GetFile(attachment.id, authContext);
    if (data.isSuccess) {
      setPDFData(`data:${data.data.contentType};base64,${data.data.filePath}`);
    } else {
      AlertService.error(data.errorMessage);
    }
    setLoading(false);
  }, [attachment.id, authContext]);

  useEffect(() => {
    downloadFile();
  }, [downloadFile, authContext]);

  return (
    <Fragment>
      {loading === false &&
        <div className="attachment">
          {/* {no}.{" "}
        <button className={`btn-icon ${loading ? 'loading' : ''}`} onClick={downloadFile} title="Click on the filename to preview the file.">
          {attachment.fileName}{loading ? ' ...' : ''}
        </button> */}
          {/* <Sidebar visible={visible} onHide={() => setVisible(false)} fullScreen>
          {pdfData && <Viewer src={pdfData}></Viewer>}
        </Sidebar> */}
          {pdfData && <Viewer src={pdfData}></Viewer>}
        </div>}
    </Fragment>
  );
};

export default Attachment;
