import { Fragment, useEffect, useState } from "react";
import { Checkbox } from 'primereact/checkbox';

const MCQExamQuestion = ({
    index,
    isExpired,
    selectedIndex,
    input,
    changeHandler
}) => {
    const quizId = (input && input.quizId) ?? 0;
    const question = input && input.question;
    const optionId1 = input && input.options[0].id;
    const optionId2 = input && input.options[1].id;
    const optionId3 = input && input.options[2].id;
    const optionId4 = input && input.options[3].id;
    const optionId5 = input && input.options[4].id;
    const option1 = input && input.options[0].option;
    const option2 = input && input.options[1].option;
    const option3 = input && input.options[2].option;
    const option4 = input && input.options[3].option;
    const option5 = input && input.options[4].option;
    const [isAnswer1, setIsAnswer1] = useState(input && input.options[0].isAnswer);
    const [isAnswer2, setIsAnswer2] = useState(input && input.options[1].isAnswer);
    const [isAnswer3, setIsAnswer3] = useState(input && input.options[2].isAnswer);
    const [isAnswer4, setIsAnswer4] = useState(input && input.options[3].isAnswer);
    const [isAnswer5, setIsAnswer5] = useState(input && input.options[4].isAnswer);

    useEffect(() => {
        input.question = question;
        input.orderBy = index;
        input.options = [
            {
                id: optionId1,
                option: option1,
                isAnswer: isAnswer1,
                orderBy: 1
            }, {
                id: optionId2,
                option: option2,
                isAnswer: isAnswer2,
                orderBy: 2
            }, {
                id: optionId3,
                option: option3,
                isAnswer: isAnswer3,
                orderBy: 3
            }, {
                id: optionId4,
                option: option4,
                isAnswer: isAnswer4,
                orderBy: 4
            }, {
                id: optionId5,
                option: option5,
                isAnswer: isAnswer5,
                orderBy: 5
            }
        ];
        changeHandler(index, input);
    }, [index, quizId, isAnswer1, isAnswer2, isAnswer3, isAnswer4, isAnswer5]);

    return (
        <Fragment>
            <div className={selectedIndex === index ? 'd-block' : 'd-none'}>
                <div className="row m-0 pl-3 pr-3">
                    <div className="col-10">
                        <label className="form-control question">{question}</label>
                    </div>
                </div>
                <div className="row m-0 pl-3 pr-3">
                    <div className="col-10">
                        <div className="flex align-items-center pl-3">
                            <Checkbox disabled={isExpired} checked={isAnswer1} onChange={(e) => {
                                setIsAnswer1(e.target.checked);
                                setIsAnswer2(false);
                                setIsAnswer3(false);
                                setIsAnswer4(false);
                                setIsAnswer5(false);
                            }} />
                            <label className="m-auto ml-2 p-2">{option1}</label>
                        </div>
                    </div>
                </div>
                <div className="row m-0 pl-3 pr-3">
                    <div className="col-10">
                        <div className="flex align-items-center pl-3">
                            <Checkbox disabled={isExpired} checked={isAnswer2} onChange={(e) => {
                                setIsAnswer2(e.target.checked);
                                setIsAnswer1(false);
                                setIsAnswer3(false);
                                setIsAnswer4(false);
                                setIsAnswer5(false);
                            }} />
                            <label className="m-auto ml-2 p-2">{option2}</label>
                        </div>
                    </div>
                </div>
                <div className="row m-0 pl-3 pr-3">
                    <div className="col-10">
                        <div className="flex align-items-center pl-3">
                            <Checkbox disabled={isExpired} checked={isAnswer3} onChange={(e) => {
                                setIsAnswer3(e.target.checked);
                                setIsAnswer1(false);
                                setIsAnswer2(false);
                                setIsAnswer4(false);
                                setIsAnswer5(false);
                            }} />
                            <label className="m-auto ml-2 p-2">{option3}</label>
                        </div>
                    </div>
                </div>
                <div className="row m-0 pl-3 pr-3">
                    <div className="col-10">
                        <div className="flex align-items-center pl-3">
                            <Checkbox disabled={isExpired} checked={isAnswer4} onChange={(e) => {
                                setIsAnswer4(e.target.checked);
                                setIsAnswer1(false);
                                setIsAnswer2(false);
                                setIsAnswer3(false);
                                setIsAnswer5(false);
                            }} />
                            <label className="m-auto ml-2 p-2">{option4}</label>
                        </div>
                    </div>
                </div>
                <div className="row m-0 pl-3 pr-3">
                    <div className="col-10">
                        <div className="flex align-items-center pl-3">
                            <Checkbox disabled={isExpired} checked={isAnswer5} onChange={(e) => {
                                setIsAnswer5(e.target.checked);
                                setIsAnswer1(false);
                                setIsAnswer2(false);
                                setIsAnswer3(false);
                                setIsAnswer4(false);
                            }} />
                            <label className="m-auto ml-2 p-2">{option5}</label>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default MCQExamQuestion;
