import request from "./request";

const QuizMCQExamService = {
  GetAll: async (id, context) => {
    return await request.sendRequest(`/quizmcqexam/all/${id}`, context.token);
  },
  Start: async (id, context) => {
    return await request.sendPutRequest(`/quizmcqexam/start/${id}`, context);
  },
  GetAttendees: async (id, searchText, context) => {
    return await request.sendRequest(`/quizmcqexam/attendees/-1/0/${id}/${searchText}`, context.token);
  },
  CalculateScore: async (id, context) => {
    return await request.sendPutRequest(`/quizmcqexam/calculate/${id}`, context);
  },
  RecalculateScore: async (id, context) => {
    return await request.sendPutRequest(`/mcqexam/re-calculate/${id}`, context);
  },
  Save: async (payload, context) => {
    return await request.sendPostRequest(`/quizmcqexam`, payload, context);
  },
};

export default QuizMCQExamService;
