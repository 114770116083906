import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import Attachment from "./attachment";
import SupportService from "../../../services/support.service";
import AuthContext from "../../../auth/auth-context";
import Comment from "./comment";
import ViewComment from "./viewcomment";

const View = ({ ticket, clearSelection }) => {
  const [comments, setComments] = useState();

  const authContext = useContext(AuthContext);

  const loadComments = useCallback(async () => {
    if (ticket) {
      let data = await SupportService.GetComments(ticket.id, authContext);
      if (data.isSuccess) {
        setComments(data.data);
      }
    }
  }, [ticket, authContext]);

  useEffect(() => {
    loadComments();
  }, [loadComments, ticket, authContext]);

  const onCommentSaveHandler = async () => {
    await loadComments();
  }

  const onCommentCancelHandler = async () => {

  }


  return (
    <Fragment>
      {document && (
        <>
          <div className="row m-0">
            <div className="col-12 course-title">
              <span>Ticket#: </span> <span className="course-title-value">{ticket.ticketId}</span>
              <button type="button" className="btn-incident-back" onClick={clearSelection}>
                <i className="fa fa-chevron-circle-left"></i> <span>Back</span>
              </button>
            </div>
          </div>
          <div className="ticket-container">
            <div className="row m-0">
              <div className="col-12 border-bottom">
                <span>Status: </span> {ticket.statusDesc}
              </div>
              <div className="col-12 border-bottom">
                <span>Category: </span> {ticket.categoryDesc}
              </div>
              <div className="col-12 border-bottom">
                <span>Course Name: </span> {ticket.courseName}
              </div>
              <div className="col-12 border-bottom">
                <span>Subject: </span> {ticket.subject}
              </div>
              <div className="col-12 border-bottom">
                <span>Description</span> <br />
                {ticket.description}
              </div>
              <div className="col-12 border-bottom">
                <span>Attachments</span>
                <br />
                {ticket.attachments &&
                  ticket.attachments.length > 0 &&
                  ticket.attachments.map((m, i) => (
                    <Attachment key={i} no={i} attachment={m}></Attachment>
                  ))}
              </div>
              {comments && comments.length > 0 &&
                <div className="col-12">
                  <span>Comments</span>
                  <br />
                  {comments && comments.length > 0 &&
                    comments.map((m, i) => (
                      <div key={i} className="p-1">
                        <ViewComment comment={m}></ViewComment>
                      </div>
                    ))}
                </div>
              }
              <div className="col-12">
                <Comment ticketId={ticket.id} submitHandler={onCommentSaveHandler} cancelHandler={onCommentCancelHandler}></Comment>
              </div>
            </div>
          </div>
        </>
      )
      }
    </Fragment >
  );
};

export default View;
