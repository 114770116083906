import request from "./request";

const ReviewQuizMCQExamService = {
  Get: async (id, context) => {
    return await request.sendRequest(`/reviewquizmcqexam/${id}`, context.token);
  },
  GetByExam: async (id, context) => {
    return await request.sendRequest(`/reviewquizmcqexam/exam/${id}`, context.token);
  },
};

export default ReviewQuizMCQExamService;
