import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown } from 'primereact/dropdown';
import AuthContext from "../../../auth/auth-context";
import ReportService from "../../../services/report.service";
import AlertService from "../../../services/alert.service";
import { CSVLink } from "react-csv";
import "../support/support.css";
import CourseService from "../../../services/course.service";

const IncidentMISReport = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState([]);
    const [courses, setCourses] = useState([]);
    const [courseId, setCourseId] = useState(0);

    const headers = [
        { label: "Learner Name", key: "userName" },
        { label: "Total Incidents", key: "totalIncidents" },
        { label: "Time Spent", key: "timeSpentDesc" },
        { label: "Completed", key: "completed" },
        { label: "In-Complete", key: "inComplete" }];

    const authContext = useContext(AuthContext);
    const supportAccess = authContext.getPermissions("incidentmisreport");
    if (supportAccess.filter((f) => f.access.includes(1)).length === 0 || authContext.onGoingValidations) {
        navigate("/");
    }

    const loadCourses = useCallback(async () => {
        setLoading(true);
        const data = await CourseService.GetCourses(authContext);
        if (data.isSuccess) {
            setLoading(false);
            if (data.data.length > 1) {
                data.data.splice(0, 0, { value: 0, label: 'All' });
            }
            setCourses(data.data);
        } else {
            setLoading(false);
            AlertService.error(data.errorMessage);
        }
    }, [authContext]);

    const loadResults = useCallback(async () => {
        setLoading(true);
        const data = await ReportService.getIncidentMISReport(courseId, authContext);
        if (data.isSuccess) {
            setLoading(false);
            setResults(data.data);
        } else {
            setLoading(false);
            AlertService.error(data.errorMessage);
        }
    }, [courseId, authContext]);

    useEffect(() => {
        loadCourses();
    }, [loadCourses, authContext]);

    useEffect(() => {
        loadResults();
    }, [loadResults, courseId, authContext]);

    return (
        <Fragment>
            <div className="row m-0">
                <div className="col-6">
                    <h1 className="title">Incident Report</h1>
                </div>
                <div className="col-6">
                    <button type="button" className="btn-incident-back float-right" onClick={() => navigate('/reports')}>
                        <i className="fa fa-chevron-circle-left"></i> <span>Back</span>
                    </button>
                    <CSVLink data={results} filename={"Incident-Results.csv"} className="btn btn-success float-right mr-2 ml-2 mt-1" headers={headers}><i className="fa fa-file-excel-o"></i></CSVLink>
                    <div className="btn-group float-right" role="group">
                        <Dropdown value={courseId} onChange={(e) => setCourseId(e.value)} options={courses} optionLabel="label"
                            placeholder="Select a course" className="w-full" />
                    </div>
                </div>
            </div>
            <div className="row m-0">
                <div className='col-12'>
                    <div className="tickets">
                        <div className="row m-0">
                            <div className="col-4 text-center header-cell">Learner Name</div>
                            <div className="col-2 text-center header-cell">Total Incidents</div>
                            <div className="col-2 text-center header-cell">Time Spent</div>
                            <div className="col-2 text-center header-cell">Completed</div>
                            <div className="col-2 text-center header-cell">In-Complete</div>
                        </div>
                        <div className="scroller">
                            {results &&
                                results.length > 0 &&
                                results.map((result, i) => (
                                    <div key={i} className="row m-0 records">
                                        <div className="col-4 value-cell">{result.userName}</div>
                                        <div className="col-2 value-cell">{result.totalIncidents}</div>
                                        <div className="col-2 value-cell">{result.timeSpentDesc}</div>
                                        <div className="col-2 value-cell">{result.completed}</div>
                                        <div className="col-2 value-cell">{result.inComplete}</div>
                                    </div>
                                ))}
                            {results && results.length === 0 && (
                                <div className="row m-0 no-records">
                                    <div className="col-12 value-cell text-center">
                                        {loading ? "Loading..." : "No data found"}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default IncidentMISReport;
