// return <iframe title={'Cyber Bay'} src="https://cybersecurity.nuvepro.io/"></iframe>;

import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../auth/auth-context";
import CourseService from "../../../services/course.service";
import VMService from "../../../services/vm.service";
import AlertService from "../../../services/alert.service";

const Bays = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [vmStarted, setVMStarted] = useState(false);
  const [vmText, setVMText] = useState('Start Lab / Stop Lab');
  const [vmUrl, setVMUrl] = useState();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [mappedCourses, setMappedCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState();
  const authContext = useContext(AuthContext);

  useEffect(() => {
    if (authContext.onGoingValidations) {
      navigate("/");
    }
  }, [navigate, authContext]);

  const checkVMStatus = useCallback(async (m) => {
    setVMText('Checking...');
    setVMStarted(false);
    const result = await VMService.statusVM(m.id, authContext);
    setVMText('Start Lab / Stop Lab');
    if (result.isSuccess) {
      if (result.data.status === 'VM running') {
        setVMStarted(true);
        setVMUrl(result.data.shareableLink);
        setUsername(result.data.userName);
        setPassword(result.data.password);
      }
    } else {
      setVMText(result.errorMessage);
      AlertService.error(result.errorMessage);
    }
  }, [authContext]);

  const loadCourses = useCallback(async () => {
    setLoading(true);
    const data = await CourseService.GetMappedCourses(authContext);
    if (data.isSuccess) {
      setLoading(false);
      setMappedCourses(data.data);
      if (data.data.length === 1) {
        setSelectedCourse(data.data[0]);
        await checkVMStatus(data.data[0]);
      }
    } else {
      setLoading(false);
      AlertService.error(data.errorMessage);
    }
  }, [authContext, checkVMStatus]);

  useEffect(() => {
    loadCourses();
  }, [loadCourses, authContext]);

  const startVM = async (e) => {
    e.preventDefault();
    setVMText('Starting...');
    const result = await VMService.startVM(selectedCourse.id, authContext);
    setVMText('Start Lab / Stop Lab');
    if (result.isSuccess) {
      setVMStarted(true);
      await checkVMStatus(selectedCourse);
      AlertService.success('Lab started successfully!!');
    } else {
      // setVMText(result.errorMessage);
      AlertService.error(result.errorMessage);
    }
  }

  const stopVM = async (e) => {
    e.preventDefault();
    setVMStarted(false);
    setVMText('Stopping...');
    const result = await VMService.stopVM(selectedCourse.id, authContext);
    setVMText('Start Lab / Stop Lab');
    if (result.isSuccess) {
      setVMStarted(false);
      await checkVMStatus(selectedCourse);
      AlertService.success('Lab stopped successfully!!');
    } else {
      // setVMText(result.errorMessage);
      AlertService.error(result.errorMessage);
    }
  }

  return (
    <Fragment>
      {loading && <div className="col-12">Loading...</div>}
      {!loading && <>
        <div className="row m-0 pl-3 pr-3">
          <div className="col-6">
            <h1 className="title">Cyber Commandos</h1>
          </div>
          <div className="col-6">
            <button type="button" className="btn-incident-back" onClick={() => navigate('/')}>
              <i className="fa fa-chevron-circle-left"></i> <span>Back</span>
            </button>
          </div>
        </div>
        {
          (selectedCourse === undefined || selectedCourse === null || selectedCourse.courseId === 0) && mappedCourses.length === 0 &&
          <div className="row m-0 pl-3 pr-3">
            <div className="col-12 text-center">
              No Labs Available!!
            </div>
          </div>
        }
        {
          (selectedCourse === undefined || selectedCourse === null || selectedCourse.courseId === 0) && mappedCourses.length > 1 &&
          <div className="row m-0 pl-3 pr-3">
            {
              mappedCourses.map((m, i) => (
                <div key={m.courseId} className="col-3 text-center cursor-pointer" onClick={() => {
                  setSelectedCourse(m);
                  checkVMStatus(m);
                }}>
                  <div className="commando-container">
                    <div className="title">Lab {i + 1}</div>
                    <div className="body">
                      <span>{m.courseName}</span>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        }
        {
          selectedCourse !== undefined && selectedCourse !== null && selectedCourse.courseId > 0 &&
          <div className="row m-0 pl-3 pr-3 justify-content-end">
            {/* <div className="col-6 text-center">
              <div className="commando-container">
                <div className="title">Cyber Range</div>
                <div className="body">
                  <div>
                    <label>Total Hours: </label><span> 15 Hours</span>
                  </div>
                  <div>
                    <label>Consumed Hours: </label><span> 2h 30m</span>
                  </div>
                  <div>
                    <label>Remaining Hours: </label><span> 12h 30m</span>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="col-12 text-center">
              {vmStarted === false &&
                <button className="submit-button" onClick={startVM}>
                  {vmText}
                </button>
              }
              {vmStarted === true &&
                <button className="submit-button" onClick={stopVM}>
                  {vmText}
                </button>
              }
            </div>
            {vmStarted === true && vmUrl && <div className="col-12 text-center">
              <a className="submit-button" href={vmUrl} target="_blank" rel="noreferrer">
                Launch Lab
              </a>
              <center>
                <div className="commando-container mt-3">
                  <div className="title">Credentials</div>
                  <div className="body">
                    <div>
                      <label>Username: </label><span> {username}</span>
                    </div>
                    <div>
                      <label>Password: </label><span> {password}</span>
                    </div>
                  </div>
                </div>
              </center>
            </div>
            }
          </div>
        }
      </>
      }
    </Fragment>
  );
};

export default Bays;
