import request from "./request";

const QuestionsService = {
  GetAllQuestions: async (context) => {
    return await request.sendRequest(`/Questions/all`, context.token);
  },
  GetAllAnswers: async (context) => {
    return await request.sendRequest(`/Questions/answers`, context.token);
  },
  Save: async (body, context) => {
      return await request.sendPostRequest(`/Questions`, body, context);
  },
};

export default QuestionsService;
