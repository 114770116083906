import "../app.css";
import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../auth/auth-context";
import CatalogueService from "../../../services/catalogue.service";
import AlertService from "../../../services/alert.service";

const Types = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [showAdd, setShowAdd] = useState(false);

  const authContext = useContext(AuthContext);

  useEffect(() => {
    const catalogueAccess = authContext.getPermissions("catalogues");
    if (catalogueAccess.filter((f) => f.access.includes(1)).length === 0 || authContext.onGoingValidations) {
      navigate("/");
    }
    setShowAdd(catalogueAccess.filter((f) => f.access.includes(2)).length > 0);
  }, [navigate, authContext]);

  const loadCategories = useCallback(async () => {
    setLoading(true);
    const data = await CatalogueService.GetCategoryList(authContext);
    if (data.isSuccess) {
      setLoading(false);
      setCategories(data.data);
      setAllCategories(data.data);
    } else {
      setLoading(false);
      AlertService.error(data.errorMessage);
    }
  }, [authContext]);

  useEffect(() => {
    loadCategories();
  }, [loadCategories]);

  useEffect(() => {
    setCategories(allCategories.filter(f => f.label.toLowerCase().indexOf(searchText.toLowerCase()) !== -1));
  }, [searchText, allCategories]);

  const onCardClick = async (id) => {
    navigate(`/catalogues/types/${id}`);
  };

  const onAddHandler = async () => {
    navigate("/catalogues/add");
  };

  return (
    <Fragment>
      {loading === false &&
        <div className="container-fluid">
          <div className="row">
            <div className="col-3">
              <div className="form-group has-search search-group catalogue-search">
                <span className="fa fa-search form-control-feedback"></span>
                <input type="text" value={searchText} onChange={(e) => setSearchText(e.target.value)} className="form-control" placeholder="Search" />
                {searchText && <span className="input-group-append">
                  <button className="btn border-0" type="button" onClick={() => setSearchText('')}>
                    <i className="fa fa-times"></i>
                  </button>
                </span>}
              </div>
            </div>
            <div className="col-3"></div>
            <div className="col-6 course-title">
              <button type="button" className="btn-incident-back" onClick={() => navigate('/')}>
                <i className="fa fa-chevron-circle-left"></i> <span>Back</span>
              </button>
              <a className="btn-incident-all" href="#/catalogues">
                View All Incidents
              </a>
            </div>
          </div>
          <div className="row">
            {categories &&
              categories.map((category, i) => (
                <div key={i} className="col-lg-2 col-md-4 col-sm-6 col-12">
                  <div className="card" onClick={() => onCardClick(category.value)} >
                    <div>
                      <div className="name" title={category.label}>
                        {category.label}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            {categories && categories.length === 0 && (
              <div className="col-12">No category available...</div>
            )}
          </div>
        </div>
      }
      {showAdd && (
        <button
          type="button"
          onClick={onAddHandler}
          className="floating-button"
        >
          <i className="fa fa-plus"></i>
        </button>
      )}
    </Fragment >
  );
};

export default Types;
