import "../../app.css";
import AlertService from "../../../../services/alert.service";
import QuizService from "../../../../services/quiz.service";
import CourseService from "../../../../services/course.service";
import AuthContext from "../../../../auth/auth-context";
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from "primereact/checkbox";
import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";

const AddQuiz = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [courses, setCourses] = useState([]);
  const times = [
    { value: 5, label: '5 mins' },
    { value: 10, label: '10 mins' },
    { value: 15, label: '15 mins' },
    { value: 20, label: '20 mins' },
    { value: 25, label: '25 mins' },
    { value: 30, label: '30 mins' },
    { value: 35, label: '35 mins' },
    { value: 40, label: '40 mins' },
    { value: 45, label: '45 mins' },
    { value: 50, label: '50 mins' },
    { value: 55, label: '55 mins' },
    { value: 60, label: '60 mins' },
  ];

  const [quizId, setQuizId] = useState(0);
  const [quizName, setQuizName] = useState();
  const [courseId, setCourseId] = useState();
  const [mcq, setMCQ] = useState(false);
  const [quizTimeInMinutes, setQuizTimeInMinutes] = useState(false);

  const [quizNameIsValid, setQuizNameIsValid] = useState(true);
  const [courseIdIsValid, setCourseIdIsValid] = useState(true);
  const [quizTimeInMinutesIsValid, setQuizTimeInMinutesIsValid] = useState(true);

  const quizNameRef = useRef();

  const authContext = useContext(AuthContext);

  const quizAccess = authContext.getPermissions("quizmaster");
  if (quizAccess.filter((f) => f.access.includes(1)).length === 0) {
    navigate("/");
  }
  const showAdd = quizAccess.filter((f) => f.access.includes(2)).length > 0;
  const showEdit = quizAccess.filter((f) => f.access.includes(3)).length > 0;
  const showDelete = quizAccess.filter((f) => f.access.includes(4)).length > 0;

  const loadCourses = useCallback(async () => {
    setLoading(true);
    const data = await CourseService.GetCourses(authContext);
    if (data.isSuccess) {
      setLoading(false);
      setCourses(data.data);
    } else {
      setLoading(false);
      AlertService.error(data.errorMessage);
    }
  }, [authContext]);

  useEffect(() => {
    loadCourses();
  }, [loadCourses, authContext]);

  const loadQuiz = useCallback(async () => {
    setLoading(true);
    const data = await QuizService.Get(params.id, authContext);
    if (data.isSuccess) {
      setLoading(false);
      setQuizName(data.data.quizName);
      setCourseId(data.data.courseId);
      setMCQ(data.data.mcq);
      setQuizTimeInMinutes(data.data.quizTimeInMinutes);
    } else {
      setLoading(false);
      AlertService.error(data.errorMessage);
    }
  }, [authContext, params.id]);

  useEffect(() => {
    if (params.id) {
      setQuizId(params.id);
      loadQuiz();
    }
  }, [loadQuiz, authContext, params.id]);


  const onCancelHandler = async (event) => {
    if (params.id) {
      navigate(`/master/quizzes/view/${params.id}`);
    } else {
      navigate("/master/quizzes");
    }
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    let isValid = true;
    let quizNameValue = quizNameRef.current.value;
    if (!quizNameValue) {
      setQuizNameIsValid(false);
      isValid = false;
    }
    if (!courseId) {
      setCourseIdIsValid(false);
      isValid = false;
    }
    if (!quizTimeInMinutes) {
      setQuizTimeInMinutesIsValid(false);
      isValid = false;
    }

    if (isValid) {
      setLoading(true);
      try {
        let payload = {
          quizName: quizNameValue,
          courseId: courseId,
          mcq: mcq,
          quizTimeInMinutes: quizTimeInMinutes
        };
        if (quizId > 0) {
          payload.id = quizId;
        }
        let data = await QuizService.Save(payload, authContext);
        if (data.isSuccess) {
          AlertService.success('Quiz details are saved successfully!');
          navigate("/master/quizzes");
        } else {
          AlertService.error(data.errorMessage);
        }
        setLoading(false);
      } catch (ex) {
        console.log(ex);
        setLoading(false);
      }
    }
  };

  const onConfirmDeleteHandler = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Are you sure you want to delete this quiz?",
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "accept",
      accept: onDeleteHandler,
    });
  };

  const onDeleteHandler = async () => {
    let data = await QuizService.Remove(quizId, authContext);
    if (data.isSuccess) {
      AlertService.success("Quiz details are deleted successfully!");
      navigate("/master/quizzes");
    } else {
      AlertService.error(data.errorMessage);
    }
  };

  return (
    <Fragment>
      {!loading && (
        <form onSubmit={onSubmitHandler}>
          <div className="row pt-4 catalogue">
            <div className="col-md-1 col-sm-2 d-xs-none"></div>
            <div className="col-md-2 col-sm-3 col-xs-12 label">Quiz Name</div>
            <div className="col-md-8 col-sm-5 col-xs-12 value">
              <input
                type="text"
                className="form-control"
                placeholder="Quiz Name"
                maxLength="150"
                defaultValue={quizName}
                ref={quizNameRef}
                onChange={(e) => {
                  setQuizName(e.value);
                  setQuizNameIsValid(true);
                }}
              />
              {!quizNameIsValid && (
                <p className="error">Please provide quiz name</p>
              )}
            </div>
            <div className="col-md-1 col-sm-2 d-xs-none"></div>
          </div>
          <div className="row pt-4">
            <div className="col-md-1 col-sm-2 d-xs-none"></div>
            <div className="col-md-2 col-sm-3 col-xs-12 label">Course</div>
            <div className="col-md-8 col-sm-5 col-xs-12 value">
              <Dropdown value={courseId} onChange={(e) => setCourseId(e.value)} options={courses} optionLabel="label"
                placeholder="Select a course" className="w-full" />
              {!courseIdIsValid && (
                <p className="error">Please select a course</p>
              )}
            </div>
            <div className="col-md-1 col-sm-2 d-xs-none"></div>
          </div>
          <div className="row pt-4">
            <div className="col-md-1 col-sm-2 d-xs-none"></div>
            <div className="col-md-2 col-sm-3 col-xs-12 label">Quiz Type</div>
            <div className="col-md-8 col-sm-5 col-xs-12 value">
              <div className="flex align-items-center">
                <Checkbox
                  inputId="mcqId"
                  onChange={(e) => setMCQ(e.checked)}
                  checked={mcq}
                ></Checkbox><label htmlFor="mcqId" className="ml-2 mt-1 mr-2"> MCQ</label>
              </div>
            </div>
            <div className="col-md-1 col-sm-2 d-xs-none"></div>
          </div>
          <div className="row pt-4">
            <div className="col-md-1 col-sm-2 d-xs-none"></div>
            <div className="col-md-2 col-sm-3 col-xs-12 label">Duration </div>
            <div className="col-md-8 col-sm-5 col-xs-12 value">
              <Dropdown value={quizTimeInMinutes} onChange={(e) => setQuizTimeInMinutes(e.value)} options={times} optionLabel="label"
                placeholder="Select a duration" className="w-full" />
              {!quizTimeInMinutesIsValid && (
                <p className="error">Please select duration</p>
              )}
            </div>
            <div className="col-md-1 col-sm-2 d-xs-none"></div>
          </div>
          <div className="row pt-2 mb-3">
            <div className="col-md-12 col-sm-12">
              <div className="text-center pt-4">
                {((showAdd && quizId === 0) || (showEdit && quizId > 0)) &&
                  <button
                    type="submit"
                    className="submit-button"
                    disabled={loading}>
                    Submit
                  </button>}
                {showDelete && quizId > 0 && (
                  <>
                    <ConfirmPopup />
                    <button
                      type="button"
                      className="remove-button ml-3"
                      onClick={onConfirmDeleteHandler}
                      disabled={loading}
                    >
                      Delete
                    </button>
                  </>
                )}
                <button
                  type="button"
                  className="cancel-button ml-3"
                  onClick={onCancelHandler}
                  disabled={loading}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </form>
      )
      }
    </Fragment >
  );
};

export default AddQuiz;
