
const storeKey = process.env.REACT_APP_USER_KEY;

const handler = (err) => {
    if (err.message && err.message === "Failed to fetch") {
        const myResp = { isSuccess: false, errorMessage: 'Server is not reachable!!' };
        const myOptions = { status: 200, statusText: "" };
        return new Response(JSON.stringify(myResp), myOptions);
    }
    if (err.status === 401) {
        document.cookie = storeKey + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
}

export default handler;