import request from "./request";

const UserService = {
  GetAll: async (context) => {
    return await request.sendRequest(`/user/all`, context.token);
  },
  GetAllExceptLearners: async (context) => {
    return await request.sendRequest(`/user/all/except`, context.token);
  },
  GetAllLearners: async (context) => {
    return await request.sendRequest(`/user/all/learners`, context.token);
  },
  Get: async (userId, context) => {
    return await request.sendRequest(`/user/${userId}`, context.token);
  },
  GetProfile: async (context) => {
    return await request.sendRequest(`/user/profile`, context.token);
  },
  GetList: async (context) => {
    return await request.sendRequest(`/user/list/1`, context.token);
  },
  ForgotPassword: async (emailId) => {
    let email = encodeURIComponent(emailId);
    return await request.sendRequest(`/user/sendresetlink/${email}`);
  },
  ResetPassword: async (model) => {
    return await request.sendUnAuthPostRequest(`/user/resetpassword`, model);
  },
  Reset: async (id, context) => {
    return await request.sendRequest(`/user/reset/${id}`, context.token);
  },
  ApproveUser: async (model) => {
    return await request.sendUnAuthPostRequest(`/user/approve`, model);
  },
  RejectUser: async (model) => {
    return await request.sendUnAuthPostRequest(`/user/reject`, model);
  },
  ValidateReCaptcha: async (response) => {
    const formData = new FormData();
    formData.append("response", response);
    return await request.sendUnAuthPostFormDataRequest(
      `/user/validatecaptcha/`,
      formData
    );
  },
  GetQrCode: async (context) => {
    return await request.sendRequest(`/user/qrcode`, context.token);
  },
  ReConfigureQrCode: async (code, context) => {
    return await request.sendRequest(
      `/user/reconfigureqrcode/${code}`,
      context.token
    );
  },
  ValidateQrCode: async (response, context) => {
    return await request.sendPostRequest(
      `/user/validateqrcode`,
      response,
      context
    );
  },
  VerifyQrCode: async (response, context) => {
    return await request.sendPostRequest(
      `/user/verifyqrcode`,
      response,
      context
    );
  },
  SendOTP: async (context) => {
    return await request.sendRequest(`/user/sendotp`, context.token);
  },
  SendMFAOTP: async (context) => {
    return await request.sendRequest(`/user/mfaotp`, context.token);
  },
  ValidateOTP: async (otp, context) => {
    return await request.sendPostRequest(`/user/validateotp`, otp, context);
  },
  GetMFAQ: async (context) => {
    return await request.sendRequest(`/user/mfaq`, context.token);
  },
  ValidateMFAOTP: async (otp, context) => {
    return await request.sendPostRequest(`/user/validatemfaotp`, otp, context);
  },
  ValidateSQ: async (sqa, context) => {
    return await request.sendPostRequest(`/user/validatesq`, sqa, context);
  },
  UpdateProfile: async (sqa, context) => {
    return await request.sendPutRequestWithBody(`/user/profile`, sqa, context);
  },
  Save: async (payload, context) => {
    return await request.sendPostRequest(`/user`, payload, context);
  },
  SaveLearner: async (payload, context) => {
    return await request.sendPostRequest(`/user/learner`, payload, context);
  },
  ResetMFA: async (id, context) => {
    return await request.sendPutRequest(`/user/resetmfa/${id}`, context);
  },
  Import: async (payload, context) => {
    return await request.sendFormDataRequest(`/user/import`, payload, context);
  },
  Remove: async (id, context) => {
    return await request.sendDeleteRequest(`/user/${id}`, context.token);
  },
};

export default UserService;
