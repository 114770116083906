import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

const Viewer = ({ src }) => {
  const [numPages, setNumPages] = useState(null);

  return (
    <center>
      <Document
        file={src}
        onLoadError={console.error}
        onContextMenu={(e) => e.preventDefault()}
        onLoadSuccess={({ numPages }) => setNumPages(numPages)}
        externalLinkTarget='_blank'
        pageMode="fullScreen"
        pageLayout="singlePage"
        className="pdf-container"
      >
        {Array.apply(null, Array(numPages))
          .map((x, i) => i + 1)
          .map(page => <Page scale='1.8' size="A4" pageNumber={page} fullScreen={true} />)}
      </Document>
    </center>);
};

export default Viewer;
