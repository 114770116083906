import "../../app.css";
import './quiz.css'
import AlertService from "../../../../services/alert.service";
import ReviewQuizMCQExamService from "../../../../services/reviewquizmcqexam.service";
import AuthContext from "../../../../auth/auth-context";
import QuizMCQExamService from "../../../../services/quizmcqexam.service";
import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ExamQuestionView from "./examquestionview";

const ExamView = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const [quizExam, setQuizExam] = useState();
    const [questions, setQuestions] = useState([]);
    const authContext = useContext(AuthContext);

    const quizAccess = authContext.getPermissions("quiz");
    if (quizAccess.filter((f) => f.access.includes(1)).length === 0) {
        navigate("/");
    }

    const loadQuestions = useCallback(async () => {
        setLoading(true);
        const data = await ReviewQuizMCQExamService.GetByExam(params.id, authContext);
        if (data.isSuccess) {
            setLoading(false);
            setQuizExam(data.data);
            setQuestions(data.data.questions);
        } else {
            setLoading(false);
            AlertService.error(data.errorMessage);
        }
    }, [authContext, params.id]);

    useEffect(() => {
        if (params.id) {
            loadQuestions();
        }
    }, [loadQuestions, authContext, params.id]);

    const onCancelHandler = () => {
        navigate(`/master/quizzes/attendees/${quizExam.quizId}`);
    }

    const RecalculateScore = async () => {
        let result = await QuizMCQExamService.RecalculateScore(params.id, authContext);
        if (result.isSuccess) {
            AlertService.success('Score has been recalculated successfully.');
            loadQuestions();
        } else {
            AlertService.error(result.errorMessage);
        }
    }

    return (
        <Fragment>
            <div className="container-fluid question-container">
                <div className="row d-none d-md-flex">
                    <div className="col-12 view">
                        <div className="row m-0">
                            <div className="col-9 course-title">
                                <span className="quiz-left-title">{quizExam && quizExam.quizName}, MCQ : {quizExam && quizExam.attendeeName}, <small>Score : {quizExam && (quizExam.correctMCQAnswers + quizExam.graceMarks)}/{quizExam && quizExam.totalMCQQuestions}, {quizExam && quizExam.mcqScore}%</small></span>
                            </div>
                            <div className="col-3 course-title">
                                <button type="button" className="btn-incident-back" onClick={onCancelHandler}>
                                    <i className="fa fa-chevron-circle-left"></i> <span>Back</span>
                                </button>
                                <button type="button" className="btn-incidents-all float-right mr-2" onClick={RecalculateScore} >
                                    <span>Re-Calculate Score</span>
                                </button>
                            </div>
                        </div>
                        {!loading && questions && questions.length > 0 && questions.map((m, i) => (
                            <>
                                <ExamQuestionView key={i} input={m}></ExamQuestionView>
                                {i < (questions.length - 1) && <hr />}
                            </>
                        ))}
                        {loading && <div className="row m-0 pl-3 pr-3"><div className="col-12">Loading...</div></div>}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default ExamView;
