import { Fragment, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../auth/auth-context";

const View = ({ course }) => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);

  const courseAccess = authContext.getPermissions("courses");
  if (courseAccess.filter((f) => f.access.includes(1)).length === 0) {
    navigate("/");
  }
  const showAdd = courseAccess.filter((f) => f.access.includes(2)).length > 0;
  const showEdit = courseAccess.filter((f) => f.access.includes(3)).length > 0;

  const openDocumentsHandler = async () => {
    navigate(`/courses/documents/${course.id}`)
  }

  return (
    <Fragment>
      {course && (
        <div className="view-container">
          <div className="row m-0">
            <div className="col-12">
              <span>Course Name: </span> {course.courseName}
            </div>
            <div className="col-12">
              <span>Description</span> <br />
              {course.description}
            </div>
            {/* <div className="col-12">
              <span>Attachments</span>
              <br />
              <div className="attachment-container">
                {course.attachments &&
                  course.attachments.length > 0 &&
                  course.attachments.map((m, i) => (
                    <Attachment key={i} no={i} attachment={m}></Attachment>
                  ))}
              </div>
            </div> */}
            <div className="col-2 pt-2">
              <div className="text-center">
                <div className="course-card">
                  <img onClick={() => navigate(`/catalogues/course/${course.id}`)} src={"assets/img/catalogue.svg"} alt="" title={"Click on the card to open Catalogue page."}></img>
                  <span onClick={() => navigate(`/catalogues/course/${course.id}`)} title={"Click on the card to open Catalogue page."}>{"Catalogue"}</span>
                  {(showAdd || showEdit) && <div onClick={() => navigate(`/courses/incidents/${course.id}`)} title={"Click here to create/edit catalogues."}><i className="fa fa-cog"></i> Create / Edit</div>}
                </div>
              </div>
            </div>
            <div className="col-2 pt-2">
              <div
                className="text-center"
                // onClick={() => navigate("/bays")}
                onClick={() => course.vmUrl && course.vmUrl != null && window.open(course.vmUrl)}
                title={"Click on the card to open cyber range page."}
              >
                <div className="course-card">
                  <img src={"assets/img/bays.svg"} alt=""></img>
                  <span>{"Cyber Range"}</span>
                </div>
              </div>
            </div>
            {authContext.roleId !== 7 && <div className="col-2 pt-2">
              <div className="text-center">
                <div className="course-card">
                  <img src={"assets/img/man.svg"} alt=""></img>
                  <span>{"Users"}</span>
                  {(showAdd || showEdit) && <div onClick={() => navigate(`/courses/users/${course.id}`)} title={"Click here to create/edit users."}><i className="fa fa-cog"></i> Create / Edit</div>}
                </div>
              </div>
            </div>}
            {authContext.roleId !== 7 && <div className="col-2 pt-2">
              <div className="text-center">
                <div className="course-card">
                  <img src={"assets/img/mind-map.svg"} alt=""></img>
                  <span>{"VM/User - Mapping"}</span>
                  {(showAdd || showEdit) && <div onClick={() => navigate(`/courses/users/vms/${course.id}`)} title={"Click here to create/edit vm mapping."}><i className="fa fa-cog"></i> Create / Edit</div>}
                </div>
              </div>
            </div>}
            <div className="col-2 pt-2">
              <div
                className="text-center"
                // onClick={() => navigate("/bays")}
                onClick={openDocumentsHandler}
                title={"Click on the card to open documents page."}
              >
                <div className="course-card">
                  <img src={"assets/img/documents.png"} alt=""></img>
                  <span>{"Documents"}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default View;
