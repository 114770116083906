import { useNavigate } from "react-router-dom";
import { useContext, Fragment, useRef, useState } from "react";
import Header from "../header";
import AuthContext from "../../../auth/auth-context";
import LoginService from "../../../services/login.service";
import AlertService from "../../../services/alert.service";
import "../identity.css";

const Login = () => {
  const navigate = useNavigate();
  const [usernameIsValid, setUsernameIsValid] = useState(true);
  const [passwordIsValid, setPasswordIsValid] = useState(true);
  const [loading, setLoading] = useState(false);

  const usernameRef = useRef();
  const passwordRef = useRef();

  const authContext = useContext(AuthContext);

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    const usernameValue = usernameRef.current.value;
    const passwordValue = passwordRef.current.value;

    if (!usernameValue) {
      setUsernameIsValid(false);
      return false;
    }
    if (!passwordValue) {
      setPasswordIsValid(false);
      return false;
    }

    const payload = {
      userName: usernameValue,
      password: passwordValue,
    };
    if (usernameIsValid && passwordIsValid) {
      try {
        setLoading(true);
        const result = await LoginService.Login(payload);
        setLoading(false);
        if (result.isSuccess) {
          authContext.login(
            result.data.access_token,
            result.data.refresh_token,
            result.data.id,
            result.data.roleId,
            result.data.isAnswered,
            result.data.resetPassword,
            result.data.mfaSetupCompleted,
            result.data.mfaSetupEnabled,
            result.data.mfaSetupEnabled ? false : true,
            result.data.hasOngoingValidations,
            result.data.displayName,
            result.data.menuPermissions,
            result.data.course_count
          );
          authContext.setPermissions(result.data.menuPermissions);
          if (result.data.resetPassword) {
            navigate("/reset");
          } else if (result.data.mfaSetupEnabled === true && result.data.mfaSetupCompleted === false) {
            navigate("/qrcode");
          } else if (result.data.isAnswered === false) {
            navigate("/questions");
          } else if (result.data.mfaSetupEnabled === true) {
            navigate("/mfa");
          } else {
            navigate("/");
          }
        } else {
          setLoading(false);
          AlertService.error(result.errorMessage);
        }
      } catch (ex) {
        console.log(ex);
        setLoading(false);
      }
    }
  };

  return (
    <Fragment>
      <div className="identity-container">
        <Header />
        <div className="row identity-wrapper">
          <div className="col-xl-6 col-lg-12">
            <div className="identity-form">
              <h1>USER LOGIN 🔒</h1>
              <form onSubmit={onSubmitHandler}>
                <div className="row m-0">
                  <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                  <div className="col-xl-6 col-md-12 col-sm-12 col-xs-12 mb-3 mt-2 input-icons">
                    <div className="input-container-username">
                      <input
                        autoFocus
                        type="email"
                        id="email"
                        placeholder="Enter your email"
                        name="email"
                        required
                        maxLength="254"
                        ref={usernameRef}
                      />
                      {!usernameIsValid && (
                        <p className="error">Please enter email id</p>
                      )}
                    </div>
                  </div>
                  <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                </div>
                <div className="row m-0">
                  <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                  <div className="col-xl-6 col-md-12 col-sm-12 col-xs-12 mb-3 mt-2 input-icons">
                    <div className="input-container-password">
                      <input
                        type="password"
                        id="pwd"
                        maxLength="25"
                        placeholder="Password"
                        name="pswd"
                        required
                        ref={passwordRef}
                      />
                      {!passwordIsValid && (
                        <p className="error">Please enter password</p>
                      )}
                    </div>
                    <div className="text-right">
                      <a className="checkbox-container" href="#/forgot">
                        Forget Password?
                      </a>
                    </div>
                  </div>
                  <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                </div>
                <div className="text-center pt-4">
                  <button
                    type="submit"
                    className="identity-button"
                    disabled={loading}
                  >
                    LOGIN
                  </button>
                </div>
                <div className="text-center pt-4">
                  <button className="signup-button" disabled={loading}>
                    Sign Up
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-lg-6 d-none d-xl-block identity-image">
            <img alt="Login" src="assets/img/login.png"></img>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Login;
