import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../auth/auth-context";
import ReportService from "../../../services/report.service";
import AlertService from "../../../services/alert.service";
import "../support/support.css";

const IncidentUserReport = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState([]);

    const authContext = useContext(AuthContext);
    const supportAccess = authContext.getPermissions("incidentuserreport");
    if (supportAccess.filter((f) => f.access.includes(1)).length === 0 || authContext.onGoingValidations) {
        navigate("/");
    }

    const loadResults = useCallback(async () => {
        setLoading(true);
        const data = await ReportService.getIncidentUserReport(authContext);
        if (data.isSuccess) {
            setLoading(false);
            setResults(data.data);
        } else {
            setLoading(false);
            AlertService.error(data.errorMessage);
        }
    }, [authContext]);

    useEffect(() => {
        loadResults();
    }, [loadResults, authContext]);

    return (
        <Fragment>
            <div className="row m-0">
                <div className="col-6">
                    <h1 className="title">Incident Report</h1>
                </div>
                <div className="col-6">
                    <button type="button" className="btn-incident-back" onClick={() => navigate('/reports')}>
                        <i className="fa fa-chevron-circle-left"></i> <span>Back</span>
                    </button>
                </div>
            </div>
            <div className="row m-0">
                <div className='col-12'>
                    <div className="tickets">
                        <div className="row m-0">
                            <div className="col-5 text-center header-cell">Incident Name</div>
                            <div className="col-4 text-center header-cell">Time Spent</div>
                            <div className="col-3 text-center header-cell">Status</div>
                        </div>
                        <div className="scroller">
                            {results &&
                                results.length > 0 &&
                                results.map((result, i) => (
                                    <div key={i} className="row m-0 records">
                                        <div className="col-5 value-cell">{result.incidentName}</div>
                                        <div className="col-4 value-cell">{result.timeSpentDesc}</div>
                                        <div className="col-3 value-cell">{result.statusDesc}</div>
                                    </div>
                                ))}
                            {results && results.length === 0 && (
                                <div className="row m-0 no-records">
                                    <div className="col-12 value-cell text-center">
                                        {loading ? "Loading..." : "No data found"}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default IncidentUserReport;
