import DocumentService from "../../../services/document.service";
import AlertService from "../../../services/alert.service";
import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import AuthContext from "../../../auth/auth-context";
import Viewer from "../documents/viewer";

const DocViewer = ({ doc }) => {
    const [document, setDocument] = useState({});
    const authContext = useContext(AuthContext);

    const downloadFile = useCallback(async () => {
        setDocument(null);
        const data = await DocumentService.GetFile(doc.documentId, authContext);
        if (data.isSuccess) {
            setDocument(`data:${data.data.contentType};base64,${data.data.filePath}`);
        } else {
            AlertService.error(data.errorMessage);
        }
    }, [doc.documentId, authContext]);

    useEffect(() => {
        downloadFile();
    }, [downloadFile, doc.documentId, authContext]);

    return (
        <Fragment>
            {document &&
                <Viewer src={document}></Viewer>
            }
        </Fragment>
    )
}

export default DocViewer;