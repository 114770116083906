import { Fragment, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AuthContext from "../../../auth/auth-context";
import CourseService from "../../../services/course.service";
import AlertService from "../../../services/alert.service";
import Card from "./card";
import DocViewer from "./docViewer";

const CourseDocs = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [selectedCardId, setSelectedCardId] = useState(0);
  const [documents, setDocuments] = useState([]);
  const [document, setDocument] = useState({});
  const scrollRef = useRef();
  const authContext = useContext(AuthContext);

  const courseAccess = authContext.getPermissions("courses");
  if (courseAccess.filter((f) => f.access.includes(1)).length === 0) {
    navigate("/");
  }

  const loadDocuments = useCallback(async () => {
    setLoading(true);
    const data = await CourseService.GetAttachments(params.id, authContext);
    if (data.isSuccess) {
      setLoading(false);
      let i = 0;
      data.data.forEach((element) => {
        i++;
        element.no = i;
      });
      setDocuments(data.data);
      setSelectedCardId(data.data[0].documentId);
      setDocument(data.data[0]);
    } else {
      setLoading(false);
      AlertService.error(data.errorMessage);
    }
  }, [params.id, authContext]);

  useEffect(() => {
    loadDocuments();
  }, [loadDocuments, authContext]);

  const onCardClick = async (doc) => {
    let preId = selectedCardId;
    setSelectedCardId(doc.documentId);
    setDocument(doc);
    if (preId === undefined || preId === null || preId === 0) {
      setTimeout(() => {
        if (scrollRef.current) {
          scrollRef.current.scrollIntoView();
        }
      }, 10);
    }
  };

  return (
    <Fragment>
      {selectedCardId > 0 && !loading && (
        <>
          <div className="row d-none d-md-flex">
            <div className="col-md-2 col-sm-4">
              <div className="incident-list incident-v-scroll" >
                <div className="row m-0">
                  {documents &&
                    documents.map((doc, i) => (
                      <div key={i} className="col-12" ref={doc.documentId === selectedCardId ? scrollRef : null}>
                        <Card
                          no={doc.no}
                          id={doc.documentId}
                          title={doc.documentName}
                          active={doc.documentId === selectedCardId}
                          clickHandler={() => onCardClick(doc)}
                        ></Card>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="col-md-10 col-sm-8 view course-doc-view">
              <div className="doc-container">
                {document && document.attachments.map(m => (
                  <DocViewer doc={m}></DocViewer>
                ))}
              </div>
            </div>
          </div>
        </>
      )
      }
    </Fragment >
  );
};

export default CourseDocs;
