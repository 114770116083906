import "../../app.css";
import AlertService from "../../../../services/alert.service";
import DocumentTypeService from "../../../../services/documenttype.service";
import AuthContext from "../../../../auth/auth-context";
import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";

const AddDocumentType = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(false);

  const [documentId, setDocumentId] = useState(0);
  const [documentType, setDocumentType] = useState();

  const [documentTypeIsValid, setDocumentTypeIsValid] = useState(true);

  const documentTypeRef = useRef();

  const authContext = useContext(AuthContext);

  const loadDocument = useCallback(async () => {
    setLoading(true);
    const data = await DocumentTypeService.Get(params.id, authContext);
    if (data.isSuccess) {
      setLoading(false);
      setDocumentType(data.data.documentType);
    } else {
      setLoading(false);
      AlertService.error(data.errorMessage);
    }
  }, [authContext, params.id]);

  useEffect(() => {
    if (params.id) {
      setDocumentId(params.id);
      loadDocument();
    }
  }, [loadDocument, authContext, params.id]);

  const onCancelHandler = async (event) => {
    navigate("/documents/types");
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    let isValid = true;
    let documentTypeValue = documentTypeRef.current.value;
    if (!documentTypeValue) {
      setDocumentTypeIsValid(false);
      isValid = false;
    }
    if (isValid) {
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append(`documentType`, documentTypeValue);
        if (documentId > 0) {
          formData.append(`id`, documentId);
        }
        let data = await DocumentTypeService.Save(formData, authContext);
        if (data.isSuccess) {
          navigate("/documents/types");
        } else {
          AlertService.error(data.errorMessage);
        }
        setLoading(false);
      } catch (ex) {
        console.log(ex);
        setLoading(false);
      }
    }
  };

  const onConfirmDeleteHandler = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Are you sure you want to delete this document type?",
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "accept",
      accept: onDeleteHandler,
    });
  };

  const onDeleteHandler = async () => {
    let data = await DocumentTypeService.Remove(documentId, authContext);
    if (data.isSuccess) {
      AlertService.success("Document type details deleted successfully!");
      navigate("/documents");
    } else {
      AlertService.error(data.errorMessage);
    }
  };

  return (
    <Fragment>
      <form onSubmit={onSubmitHandler}>
        <div className="row pt-4 catalogue">
          <div className="col-md-1 col-sm-2 d-xs-none"></div>
          <div className="col-md-2 col-sm-3 col-xs-12 label">
            Document Type
          </div>
          <div className="col-md-8 col-sm-5 col-xs-12 value">
            <input
              type="text"
              className="form-control"
              placeholder="Document Type"
              maxLength="150"
              defaultValue={documentType}
              ref={documentTypeRef}
              disabled={loading}
              onChange={(e) => {
                setDocumentType(e.value);
                setDocumentTypeIsValid(true);
              }}
            />
            {!documentTypeIsValid && (
              <p className="error">Please provide document type</p>
            )}
          </div>
          <div className="col-md-1 col-sm-2 d-xs-none"></div>
        </div>
        <div className="row pt-2 mb-3">
          <div className="col-md-12 col-sm-12">
            <div className="text-center pt-4">
              <button
                type="submit"
                className="submit-button"
                disabled={loading}
              >
                Submit
              </button>
              {documentId > 0 && (
                <>
                  <ConfirmPopup />
                  <button
                    type="button"
                    className="remove-button ml-3"
                    onClick={onConfirmDeleteHandler}
                    disabled={loading}
                  >
                    Delete
                  </button>
                </>
              )}
              <button
                type="button"
                className="cancel-button ml-3"
                onClick={onCancelHandler}
                disabled={loading}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </form>
    </Fragment>
  );
};

export default AddDocumentType;
