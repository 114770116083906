import request from "./request";

const AgentService = {
  GetAll: async (context) => {
    return await request.sendRequest(`/agent/all`, context.token);
  },
  Get: async (id, context) => {
    return await request.sendRequest(`/agent/${id}`, context.token);
  },
  GetAgents: async (id, context) => {
    return await request.sendRequest(`/agent/agents/${id}`, context.token);
  },
  GetAgentList: async (id, context) => {
    return await request.sendRequest(`/agent/list/${id}`, context.token);
  },
  Save: async (payload, context) => {
    return await request.sendPostRequest(`/agent`, payload, context);
  },
  Remove: async (id, context) => {
    return await request.sendDeleteRequest(`/agent/${id}`, context.token);
  },
};

export default AgentService;
