import request from "./request";

const MCQService = {
  GetAll: async (id, context) => {
    return await request.sendRequest(`/mcq/all/${id}`, context.token);
  },
  BulkSave: async (payload, context) => {
    return await request.sendPostRequest(`/mcq/bulk`, payload, context);
  },
  Save: async (payload, context) => {
    return await request.sendPostRequest(`/mcq`, payload, context);
  },
  Remove: async (id, context) => {
    return await request.sendDeleteRequest(`/mcq/${id}`, context.token);
  },
};

export default MCQService;
