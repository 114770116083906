import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown } from 'primereact/dropdown';
import AuthContext from "../../../auth/auth-context";
import ReportService from "../../../services/report.service";
import AssessmentService from "../../../services/assessment.service";
import AlertService from "../../../services/alert.service";
import { CSVLink } from "react-csv";
import "../support/support.css";

const AssessmentQuestionReport = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState([]);
    const [assessments, setAssessments] = useState([]);
    const [assessmentId, setAssessmentId] = useState(0);

    const headers = [
        { label: "Assessment Name", key: "assessmentName" },
        { label: "Question", key: "questionName" },
        { label: "Total Attendees", key: "totalAttendees" },
        { label: "Correct Answers", key: "correctAnswers" },
        { label: "Wrong Answers", key: "wrongAnswers" },];

    const authContext = useContext(AuthContext);
    const supportAccess = authContext.getPermissions("assessmentreport");
    if (supportAccess.filter((f) => f.access.includes(1)).length === 0 || authContext.onGoingValidations) {
        navigate("/");
    }

    const loadAssessments = useCallback(async () => {
        setLoading(true);
        const data = await AssessmentService.GetList(authContext);
        if (data.isSuccess) {
            setLoading(false);
            if (data.data.length > 1) {
                data.data.splice(0, 0, { value: 0, label: 'All' });
            }
            setAssessments(data.data);
        } else {
            setLoading(false);
            AlertService.error(data.errorMessage);
        }
    }, [authContext]);

    const loadResults = useCallback(async () => {
        setLoading(true);
        const data = await ReportService.getAssessmentQuestionReport(assessmentId, authContext);
        if (data.isSuccess) {
            setLoading(false);
            setResults(data.data);
        } else {
            setLoading(false);
            AlertService.error(data.errorMessage);
        }
    }, [assessmentId, authContext]);

    useEffect(() => {
        loadAssessments();
    }, [loadAssessments, authContext]);

    useEffect(() => {
        loadResults();
    }, [loadResults, assessmentId, authContext]);

    return (
        <Fragment>
            <div className="row m-0">
                <div className="col-6">
                    <h1 className="title">Assessment Question Report</h1>
                </div>
                <div className="col-6">
                    <button type="button" className="btn-incident-back float-right" onClick={() => navigate('/reports')}>
                        <i className="fa fa-chevron-circle-left"></i> <span>Back</span>
                    </button>
                    <CSVLink data={results} filename={"Assessment-Results.csv"} className="btn btn-success float-right mr-2 ml-2 mt-1" headers={headers}><i className="fa fa-file-excel-o"></i></CSVLink>
                    <div className="btn-group float-right" role="group">
                        <Dropdown value={assessmentId} onChange={(e) => setAssessmentId(e.value)} options={assessments} optionLabel="label"
                            placeholder="Select a assessment" className="w-full" />
                    </div>
                    <button type="button" className="btn-incident-all float-right mr-2" onClick={() => navigate('/reports/assessmentreport')}>
                        <span>Attendee Wise Report</span>
                    </button>
                </div>
            </div>
            <div className="row m-0">
                <div className='col-12'>
                    <div className="tickets">
                        <div className="row m-0">
                            <div className="col-3 text-center header-cell">Assessment Name</div>
                            <div className="col-6 text-center header-cell">Question</div>
                            <div className="col-1 text-center header-cell">Total Attendees</div>
                            <div className="col-1 text-center header-cell">Correct Answers</div>
                            <div className="col-1 text-center header-cell">Wrong Answers</div>
                        </div>
                        <div className="scroller">
                            {results &&
                                results.length > 0 &&
                                results.map((result, i) => (
                                    <div key={i} className="row m-0 records">
                                        <div className="col-3 value-cell">{result.assessmentName}</div>
                                        <div className="col-6 value-cell">{result.questionName}</div>
                                        <div className="col-1 value-cell text-center">{result.totalAttendees}</div>
                                        <div className="col-1 value-cell text-center">{result.correctAnswers}</div>
                                        <div className="col-1 value-cell text-center">{result.wrongAnswers}</div>
                                    </div>
                                ))}
                            {results && results.length === 0 && (
                                <div className="row m-0 no-records">
                                    <div className="col-12 value-cell text-center">
                                        {loading ? "Loading..." : "No data found"}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default AssessmentQuestionReport;
